import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage, injectIntl } from 'react-intl';
import Avatar from '@material-ui/core/Avatar';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import {
  SetPolicyChanges,
  SetPublishingStatus,
  SetPublishingAction,
  SetTaskID,
  SetInstallationProgress,
} from 'store/ducks/policyChange.duck';
import Language from 'components/Language';
import NotificationChanges from 'components/NotificationChanges';
import ObjectExplorer from 'components/ObjectExplorer';
import BeSafeContainer from 'components/commons/BeSafeContainer';

const titleHeaderMap = {
  "/": "SIDEBAR.DASHBOARD",
  "/profile": "NAVBAR.PROFILE",
  "/administration": "SIDEBAR.ADMINISTRATION",
  "/devices": "SIDEBAR.DEVICES",
  "/configuration": "SIDEBAR.CONFIGURATION",
  "/xdr": "SIDEBAR.XDR",
  "/reports": "SIDEBAR.LOGS_REPORTS",
  "/notifications": "NAVBAR.NOTIFICATIONS",
};
const approximatedTitleHeaderMap = {
  "/configuration": "SIDEBAR.CONFIGURATION",
  "/xdr": "SIDEBAR.XDR",
  device: "SIDEBAR.DEVICES",
};

const LayoutNavbar = (props) => {
  const location = useLocation();
  const [titleHeader, setTitleHeader] = useState('SIDEBAR.NO_TITLE');
  const [subtitleHeader, setSubtitleHeader] = useState('');

  const { role, accountType, isSuperUser } = useSelector(
    ({ auth }) => ({
      role: auth.user.role,
      accountType: auth.user.account_type,
      isSuperUser: auth.user?.role === 'super_admin' ? true : false,
    }),
    shallowEqual
  );

  const intl = useIntl();

  const generateTitle = path => {
    let titleHeader = titleHeaderMap[path];
    if (!titleHeader) {
      for (let key in approximatedTitleHeaderMap) {
        if (path.startsWith(key) || path.includes(key)) {
          titleHeader = approximatedTitleHeaderMap[key];
          break;
        }
      }
    }
    setTitleHeader(titleHeader || 'SIDEBAR.NO_TITLE');
  };

  const generateSubtitle = path => {
    const getElementName = () => {
      const pathname = path.split('/');
      pathname.pop();

      return pathname.pop();
    };

    if (path.includes('/configuration')) {
      return setSubtitleHeader(getElementName());
    }

    setSubtitleHeader('');
  };

  useEffect(() => {
    const path = location.pathname;

    generateTitle(path);
    generateSubtitle(path);
  }, [location.pathname]);

  const onClickManual = () => {
    let url = `${process.env.PUBLIC_URL}/media/`;

    if (accountType === 'carrier') {
      url += intl.formatMessage({ id: "USER_MANUAL.CARRIER_FILENAME" });
    } else {
      url += intl.formatMessage({ id: "USER_MANUAL.UTM_FILENAME" });
    }

    window.open(url, '_blank').focus();
  };

  return (
    <>
      <Navbar
        expand="lg"
        className="color-navbar layout-navbar align-items-lg-center container-p-x"
      >
        <span className="title-navbar">
          <FormattedMessage id={titleHeader} />

          <BeSafeContainer visible={subtitleHeader}>
            {" "}/{" "}
            <FormattedMessage
              id={"ADMINISTRATION." + subtitleHeader.toUpperCase()}
            />
          </BeSafeContainer>
        </span>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="align-items-lg-center ml-auto">
            {role !== 'super_admin' && accountType === 'utm' && <ObjectExplorer />}

            <NotificationChanges />
            <Language />
            <NavDropdown
              alignRight
              className="d-flex-navbar"
              title={
                <div className="d-flex align-items-center justify-content-center text-primary">
                  <Avatar className="mr-2 avatar-small bg-primary">
                  </Avatar>
                  <div className="d-flex flex-column">
                    <span className="font-weight-bolder">
                      {props.user?.first_name}
                    </span>
                    <span className="text-tiny font-weight-bold">
                      {props.user?.tenant_name?.toUpperCase()}
                    </span>
                  </div>
                </div>
              }
            >
              <NavDropdown.Item as={Link} to="/profile">
                <PersonIcon fontSize="small" className="mr-2" />
                <FormattedMessage id="NAVBAR.PROFILE" />
              </NavDropdown.Item>
              {
                !isSuperUser && !(accountType === 'carrier' && intl?.locale === 'en') &&
                <>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={onClickManual} >
                    <MenuBookIcon fontSize="small" className="mr-2" />
                    <FormattedMessage id="NAVBAR.MANUAL" />
                  </NavDropdown.Item>
                </>
              }
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/logout">
                <PowerSettingsNewIcon fontSize="small" className="mr-2" />
                <FormattedMessage as="div" id="NAVBAR.LOGOUT" />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool,
};

LayoutNavbar.defaultProps = {
  sidenavToggle: true,
};

const mapDispatchToProps = {
  SetPolicyChanges,
  SetPublishingStatus,
  SetPublishingAction,
  SetTaskID,
  SetInstallationProgress,
};

export default connect(
  (store) => ({
    navbarBg: store.theme.navbarBg,
    user: store.auth.user,
    changeCount: store.policy.changeCount,
    isPublishing: store.policy.isPublishing,
    publishingAction: store.policy.publishingAction,
    taskID: store.policy.taskID,
  }),
  mapDispatchToProps
)(injectIntl(LayoutNavbar));
