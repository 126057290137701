import { Add } from "@mui/icons-material";
import BeSafeSearchBar from "components/commons/BeSafeSearchBar";
import { ConfirmationPopup } from "components/ConfirmDialog";
import { errorToast, successToast, warningToast } from "utils/toastUtils";
import { useCallback, useEffect, useState } from "react";
import { ListGroup, Spinner } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import DlpObjectDetail from "./DlpObjectDetail";
import AddDlpObjectModal from "./AddDlpObjectModal";
import { deleteNetwork, getNetworks } from "app/crud/networks.crud";
import BeSafeContainer from "components/commons/BeSafeContainer";
import TableLoader from "components/commons/TableLoader";
import BeSafeButton from "components/commons/BeSafeButton";

const DlpObjectList = ({ type, ...props }) => {
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dlpObjects, setDlpObjects] = useState([]);
  const [selectedDlpObject, setSelectedDlpObject] = useState({});
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const usedList = [];

  const intl = useIntl();

  const selectDlpObject = useCallback((dlpObject) => {
    setPreviewLoading(true);
    setSelectedDlpObject(dlpObject);
    setPreviewLoading(false);
  }, []);

  const fetchDlpObjects = useCallback(
    (q) => {
      setLoading(true);
      setPreviewLoading(true);

      getNetworks({ type, q })
        .then((response) => {
          setDlpObjects(response.data.objects);
          if (response.data.objects.length) {
            selectDlpObject(response.data.objects[0]);
          }
        })
        .catch((error) => {
          errorToast({ body: "DLP_OBJECTS.LOAD_ERROR", intl: intl });
        })
        .finally(() => {
          setLoading(false);
          setPreviewLoading(false);
        });
    },
    [type, intl, selectDlpObject]
  );

  useEffect(() => {
    fetchDlpObjects(searchQuery);
  }, [fetchDlpObjects, searchQuery]);

  const showEditDlpObjectModal = () => {
    setEditMode(true);
    setShowModal(true);
  };

  const showNewDlpObjectModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const deleteDlpObject = () => {
    if (usedList.length > 0) {
      warningToast({
        body: "NETWORK_OBJECTS.ALREADY_IN_USE",
        intl: intl,
      });
    } else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "GENERAL.WARNING",
        }),
        description: intl.formatMessage({
          id: "GENERAL.CONFIRM_DELETE",
        }),
        okLabel: intl.formatMessage({
          id: "GENERAL.OK",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          deleteNetwork(selectedDlpObject.id)
            .then((response) => {
              const vals = dlpObjects.filter((ng) => ng.id !== selectedDlpObject.id);
              setDlpObjects(vals);
              if (vals.length) selectDlpObject(vals[0]);
              successToast({
                body: "DLP_OBJECTS.DELETE_SUCCESS",
                intl: intl,
              });
            })
            .catch((err) => {
              if (err.response.data.already_in_used) {
                errorToast({
                  body: "DLP_OBJECTS.ALREADY_IN_USE",
                  intl: intl,
                });
              } else {
                errorToast({
                  body: err.response.data?.error,
                  intl: intl,
                });
              }
            });
        },
      });
    }
  };

  const onCreateDlpObject = () => {
    fetchDlpObjects(searchQuery);
    setShowModal(false);
  };

  return (
    <div className="d-flex obj-cus-h-35 py-2">
      <div className="d-flex card flex-column w-40 h-100 mr-3">
        <div className="d-flex justify-content-between pl-3">
          <BeSafeSearchBar onSearch={setSearchQuery} />
          <BeSafeContainer
            visible={[
              "dlp_file_name",
              "dlp_content",
            ].includes(type)}
          >
            <BeSafeButton
              variant="transparent"
              className="py-0 ml-auto px-1"
              onClick={showNewDlpObjectModal}
              icon={<Add />}
              tooltip={intl.formatMessage({ id: "GENERAL.ADD" })}
            />
          </BeSafeContainer>
        </div>
        <div className="flex-grow-1 overflow-auto">
          <BeSafeContainer visible={loading}>
            <TableLoader />
          </BeSafeContainer>
          <BeSafeContainer visible={!loading && dlpObjects.length <= 0}>
            <span className="d-flex justify-content-center m-2 mt-4">
              <FormattedMessage id="GENERAL.NO_DATA" />
            </span>
          </BeSafeContainer>
          <BeSafeContainer visible={!loading && dlpObjects.length > 0}>
            <div className="d-flex flex-column h-100">
              <ListGroup className="flex-grow-1 overflow-auto">
                {dlpObjects.map((dlpObject) => (
                  <ListGroup.Item
                    className="rounded-0"
                    active={dlpObject.id === selectedDlpObject.id}
                    key={dlpObject.id}
                    action
                    onClick={() => selectDlpObject(dlpObject)}
                  >
                    {dlpObject.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </BeSafeContainer>
        </div>
      </div>
      <BeSafeContainer visible={previewLoading}>
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <Spinner animation="border" />
        </div>
      </BeSafeContainer>
      <BeSafeContainer visible={!previewLoading && dlpObjects.length <= 0}>
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      </BeSafeContainer>
      <BeSafeContainer visible={!previewLoading && dlpObjects.length > 0}>
        <DlpObjectDetail
          selectedDlpObject={selectedDlpObject}
          type={type}
          onDelete={deleteDlpObject}
          onEdit={showEditDlpObjectModal}
        />
      </BeSafeContainer>
      <BeSafeContainer visible={type !== "dlp_file_type"}>
        <AddDlpObjectModal
          editMode={editMode}
          showModal={showModal}
          onHide={() => setShowModal(false)}
          onCreate={() => onCreateDlpObject()}
          onUpdate={() => onCreateDlpObject()}
          selectedDlpObject={selectedDlpObject}
          type={type}
        />
      </BeSafeContainer>
    </div>
  );
};

export default DlpObjectList;
