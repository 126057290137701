import React, { useState, useEffect, useCallback } from "react";
import { ListGroup, Image } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import _ from "lodash";
import { deleteSchedule, getSchedules } from "app/crud/schedules.crud";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import PersonIcon from "@mui/icons-material/Person";
import DnsIcon from "@mui/icons-material/Dns";

import { successToast, errorToast, warningToast } from "utils/toastUtils";
import { ConfirmationPopup } from "components/ConfirmDialog";
import AddScheduleModal from "./AddScheduleModal";
import ScheduleSubList from "./ScheduleSubList";
import BeSafeSearchBar from "components/commons/BeSafeSearchBar";
import TableLoader from "components/commons/TableLoader";
import NoDataDisplay from "components/commons/NoDataDisplay";
import BeSafeContainer from "components/commons/BeSafeContainer";
import { Add } from "@mui/icons-material";
import BeSafeButton from "components/commons/BeSafeButton";

const ScheduleObjectList = (props) => {
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const usedList = [];

  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const intl = useIntl();

  const selectSchedule = useCallback(
    (schedule) => {
      setPreviewLoading(true);
      setSelectedSchedule(schedule);
      setPreviewLoading(false);
    },
    []
  );

  const fetchSchedules = useCallback(
    (q) => {
      setLoading(true);
      setPreviewLoading(true);
      getSchedules({ type: props?.type, q })
        .then((response) => {
          setSchedules(response.data.objects);
          if (response.data.objects.length) {
            selectSchedule(response.data.objects[0]);
          }
        })
        .catch((error) => {
          errorToast({ body: "SCHEDULE_OBJECTS.LOAD_ERROR", intl: intl });
        })
        .finally(() => {
          setLoading(false);
          setPreviewLoading(false);
        });
    }, 
    [intl, selectSchedule, props?.type]
  );

  useEffect(() => {
    fetchSchedules(searchQuery);
  }, [fetchSchedules, searchQuery]);

  const showEditScheduleModal = () => {
    setEditMode(true);
    setShowModal(true);
  };

  const showNewScheduleModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const deleteScheduleObject = () => {
    if (usedList.length > 0) {
      warningToast({
        body: "SCHEDULE_OBJECTS.ALREADY_IN_USE",
        intl: intl,
      });
    } else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "GENERAL.WARNING",
        }),
        description: intl.formatMessage({
          id: "GENERAL.CONFIRM_DELETE",
        }),
        okLabel: intl.formatMessage({
          id: "GENERAL.OK",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          deleteSchedule(selectedSchedule.id)
            .then((response) => {
              let vals = schedules.filter((nw) => nw.id !== selectedSchedule.id);
              setSchedules(vals);
              if (vals.length) selectSchedule(vals[0]);
              successToast({
                body: "SCHEDULE_OBJECTS.SCHEDULE_OBJECT_DELETED_SUCCESS",
                intl: intl,
              });
            })
            .catch((err) => {
              if (err.response.data.already_in_used) {
                errorToast({
                  body: "SCHEDULE_OBJECTS.ALREADY_IN_USE",
                  intl: intl,
                });
              } else {
                errorToast({
                  body: err.response.data?.error,
                  intl: intl,
                });
              }
            });
        },
      });
    }
  };

  const onCreateSchedule = () => {
    fetchSchedules(searchQuery);
    setShowModal(false);
  };

  return (
    <div className="d-flex obj-cus-h-35 py-2">
      <div className="d-flex card flex-column w-40 h-100 mr-3">
        <div className="d-flex justify-content-between pl-3">
          <BeSafeSearchBar
            onSearch={setSearchQuery}
          />
          <BeSafeButton
            variant="transparent"
            className="py-0 ml-auto px-1"
            onClick={showNewScheduleModal}
            icon={<Add />}
            tooltip="GENERAL.ADD"
          />
        </div>
        <div className="flex-grow-1 overflow-auto">
          <TableLoader visible={loading} rows={10} />
          <NoDataDisplay visible={!loading && schedules.length <= 0} />
          <BeSafeContainer visible={!loading && schedules.length > 0}>
            <div className="d-flex flex-column h-100">
              <ListGroup className="flex-grow-1 overflow-auto">
                {schedules.map((schedule) => (
                  <ListGroup.Item
                    className="rounded-0"
                    active={schedule.id === selectedSchedule.id}
                    key={schedule.id}
                    action
                    onClick={() => selectSchedule(schedule)}
                  >
                    {["schedule", "schedule_range", "mac"].includes(
                      props.type
                    ) && <LanOutlinedIcon className="mr-2" />}
                    {["user"].includes(props.type) && (
                      <PersonIcon className="mr-2" />
                    )}
                    {["fqdn"].includes(props.type) && (
                      <DnsIcon className="mr-2" />
                    )}
                    {["geolocation"].includes(props.type) && (
                      <>
                        <Image
                          src={`/images/flags/flag-icons/1x1/${_.lowerCase(
                            schedule.description
                          )}.svg`}
                          style={{ width: "15px" }}
                        />{" "}
                      </>
                    )}
                    {props.type === "geolocation" ? (
                      <>
                        <FormattedMessage
                          id={`COUNTRIES.${_.upperCase(schedule.description)}`}
                        />
                      </>
                    ) : (
                      schedule.name
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </BeSafeContainer>
        </div>
      </div>
      <>
        <ScheduleSubList
          previewLoading={previewLoading}
          schedules={schedules}
          selectedSchedule={selectedSchedule}
          deleteScheduleObject={deleteScheduleObject}
          showEditScheduleModal={showEditScheduleModal}
        />
        <AddScheduleModal
          editMode={editMode}
          showModal={showModal}
          onHide={() => setShowModal(false)}
          onCreate={() => onCreateSchedule()}
          onUpdate={() => onCreateSchedule()}
          selectedSchedule={selectedSchedule}
        />
      </>
    </div>
  );
};

export default ScheduleObjectList;
