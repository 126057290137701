import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";
import { createNetwork } from "app/crud/networks.crud";
import { errorToast, successToast } from "utils/toastUtils";
import BeSafeButton from "components/commons/BeSafeButton";

const AddFQDNModal = (props) => {
  const intl = useIntl();

  return (
    <Modal show={props.showModal} onHide={props.onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="OBJECT_EXPLORER.FQDN_ADD" />
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          fqdn: "",
          description: "",
        }}
        validate = {
          (values) => {
            const errors = {};

            if (!values.fqdn || !values.fqdn.trim()) {
              errors.fqdn = intl.formatMessage({
                id: "GENERAL.REQUIRED_FIELD",
              });
            }

            if (values.fqdn.length > 64) {
              errors.fqdn = intl.formatMessage({
                id: "GENERAL.MAX_NAME_SIZE",
              });
            }

            return errors;
          }
        }
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setSubmitting(true);

          let params = {
            name: values.fqdn,
            description: values.description,
            type: "fqdn",
          };

          createNetwork(params)
            .then((response) => {
              props.onCreate();
              successToast({
                body: "FQDN_OBJECTS.ADD_SUCCESS",
                intl: intl,
              });
            })
            .catch((err) => {
              errorToast({ body: "FQDN_OBJECTS.ADD_ERROR", intl: intl });
            })
            .finally(setSubmitting(false));
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="fqdn">
                <Form.Label>
                  <FormattedMessage id="OBJECT_EXPLORER.FQDN" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="fqdn"
                  onChange={handleChange}
                  isInvalid={touched.fqdn && errors.fqdn}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "OBJECT_EXPLORER.FQDN_PLACEHOLDER",
                  })}
                  spellCheck="false"
                />
                {errors.fqdn && touched.fqdn && (
                  <Form.Control.Feedback type="invalid">
                    {errors.fqdn}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>
                  <FormattedMessage id="GENERAL.DESCRIPTION" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  onChange={handleChange}
                  isInvalid={touched.description && errors.description}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "GENERAL.DESCRIPTION_PLACEHOLDER",
                  })}
                  spellCheck="false"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.rule}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <BeSafeButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                text={<FormattedMessage id="GENERAL.SAVE" />}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddFQDNModal;
