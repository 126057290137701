import React, { useState } from "react";
import { useEffect } from "react";
import "assets/scss/settings.scss";

const BeSafeSwitch = (props) => {
  const [status, setStatus] = useState(props.state);

  const handleChange = () => {
    props.onChange(!status);
    setStatus(!status);
  };

  useEffect(() => {
    setStatus(props.state);
  }, [props.state]);

  return (
    <>
      <input
        checked={status}
        type="checkbox"
        hidden="hidden"
        id={props.name}
        name={props.name}
        onChange={() => handleChange()}
        disabled={props.disabled}
      />
      <label
        className="switch m-0"
        style={{'opacity': props.disabled ? "0.5" : ""}}
        htmlFor={props.name}
      ></label>
    </>
  );
};

export default BeSafeSwitch;
