import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from "react-intl";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import BeSafeButton from "components/commons/BeSafeButton";
import BeSafeSeparator from 'components/commons/BeSafeSeparator'
import AzureUserListModal from 'components/networks/AzureUserListModal';
import BeSafeContainer from 'components/commons/BeSafeContainer';

const FirewallADConfiguration = ({
  showAzureUserModal,
  setShowAzureUserModal,
  azureUsersList,
  azureADConfig,
  onSubmit,
  isSubmitting,
}) => {
  const [azureConfigId, setAzureConfigId] = useState('');

  return (
    <BeSafeContainer visible={false}>
      <BeSafeSeparator />
      <Form>
        <Form.Group as={Row} controlId='ad_servers'>
          <Form.Label column sm={4} className='pl-4'>
            <FormattedMessage id='NETWORK_OBJECTS.AD_SERVERS' />
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              as="select"
              name="ad_servers"
              onChange={(e)=> setAzureConfigId(e.target.value)}
              value={azureConfigId}
              className="border-secondary"
            >
              <option value=""></option>
              {azureADConfig.map((adConfig) => (
                <option key={adConfig.ad_tenant_name} value={adConfig.id}>
                  {adConfig.ad_tenant_name}
                </option>
              ))}
            </Form.Control>
          </Col>
          <BeSafeButton
            className="button-table"
            text="Sync"
            type="submit"
            onClick={(e) => onSubmit(e, azureConfigId)}
            disabled={isSubmitting}
            icon={<CloudSyncIcon className="icon-table" />}
          />
        </Form.Group>
      </Form>
      {showAzureUserModal && azureUsersList && <AzureUserListModal
        setShowAzureUserModal={setShowAzureUserModal}
        usersList={azureUsersList}
      />}
    </BeSafeContainer>
  );
};

export default FirewallADConfiguration;