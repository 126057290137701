import React from "react";
import {
  Modal,
  Form,
  Button,
  Col,
  InputGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";

import { createNetwork, updateNetwork } from "app/crud/networks.crud";
import { successToast, errorToast } from "utils/toastUtils";
import BeSafeTooltip from "components/commons/BeSafeTooltip";

const AddDlpObjectModal = (props) => {
  const intl = useIntl();

  let initialValues = {
    name: "",
    description: "",
    target: "",
    data: {
      expression_type: ["dlp_content"].includes(props.type)
        ? "regex"
        : "wildcard",
    },
    type: props.type,
  };

  if (props.editMode)
    initialValues = {
      ...initialValues,
      ...props.selectedDlpObject,
    };

  return (
    <>
      <Modal show={props.showModal} onHide={props.onHide} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.editMode ? (
              <FormattedMessage id="DLP_OBJECTS.EDIT" />
            ) : (
              <FormattedMessage id="DLP_OBJECTS.ADD" />
            )}
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = intl.formatMessage({
                id: "GENERAL.REQUIRED_FIELD",
              });
            }

            if (!values.target) {
              errors.target = intl.formatMessage({
                id: "GENERAL.REQUIRED_FIELD",
              });
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            if (props.editMode) {
              updateNetwork({ ...values, id: props.selectedDlpObject?.id })
                .then((response) => {
                  props.onUpdate(values);
                  successToast({
                    body: "DLP_OBJECTS.EDIT_SUCCESS",
                    intl: intl,
                  });
                })
                .catch((err) => {
                  errorToast({
                    body: err.response.data?.error
                      ? err.response.data.error
                      : "DLP_OBJECTS.EDIT_ERROR",
                    intl: intl,
                  });
                })
                .finally(() => {
                  setSubmitting(false);
                });
            } else {
              createNetwork(values)
                .then((response) => {
                  props.onCreate(values.name);
                  successToast({
                    body: "DLP_OBJECTS.ADD_SUCCESS",
                    intl: intl,
                  });
                })
                .catch((err) => {
                  errorToast({
                    body: err.response.data?.error
                      ? err.response.data.error
                      : "DLP_OBJECTS.ADD_ERROR",
                    intl: intl,
                  });
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="name">
                  <Form.Label>
                    <FormattedMessage id="GENERAL.NAME" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    isInvalid={touched.name && errors.name}
                    className="border-secondary"
                    placeholder={intl.formatMessage({
                      id: "GENERAL.NAME_PLACEHOLDER",
                    })}
                    spellCheck="false"
                    value={values.name}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description">
                  <Form.Label>
                    <FormattedMessage id="GENERAL.DESCRIPTION" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    onChange={handleChange}
                    isInvalid={touched.description && errors.description}
                    className="border-secondary"
                    placeholder={intl.formatMessage({
                      id: "GENERAL.DESCRIPTION_PLACEHOLDER",
                    })}
                    spellCheck="false"
                    value={values.description}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="target">
                  <Form.Label>
                    <FormattedMessage id="DLP_OBJECTS.EXPRESSION" />
                  </Form.Label>
                  <Form.Row>
                    <Col>
                      <InputGroup hasValidation>
                        <InputGroup.Prepend>
                          <ButtonGroup toggle>
                            {!["dlp_content"].includes(
                              props.type
                            ) && (
                              <>
                                <BeSafeTooltip
                                  tooltip={
                                    <FormattedMessage id="WEB_FILTER.WILDCARD" />
                                  }
                                >
                                  <ToggleButton
                                    id="radio-wildcard"
                                    type="radio"
                                    variant="outline-primary"
                                    checked={
                                      values.data?.expression_type ===
                                      "wildcard"
                                    }
                                    value="wildcard"
                                    name="data.expression_type"
                                    onChange={handleChange}
                                    className="url-button"
                                  >
                                    {"*"}
                                  </ToggleButton>
                                </BeSafeTooltip>
                              </>
                            )}
                            <BeSafeTooltip
                              tooltip={
                                <FormattedMessage id="WEB_FILTER.REGEX" />
                              }
                            >
                              <ToggleButton
                                id="radio-regex"
                                type="radio"
                                variant="outline-primary"
                                value="regex"
                                checked={
                                  values.data?.expression_type === "regex"
                                }
                                onChange={handleChange}
                                className="url-button"
                                name="data.expression_type"
                              >
                                {"(.*)"}
                              </ToggleButton>
                            </BeSafeTooltip>
                          </ButtonGroup>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="text"
                          name="target"
                          value={values.target}
                          className="border-secondary"
                          onChange={handleChange}
                          isInvalid={touched.target && errors.target}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.target}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Form.Row>
                  <Form.Control.Feedback type="invalid">
                    {errors.target}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="py-3">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  <i className="fas fa-check mr-2" />
                  <FormattedMessage id="GENERAL.SAVE" />
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddDlpObjectModal;
