
import React, { useState, useEffect, useCallback } from 'react';
import { Card, ListGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import { CircularProgress } from "@mui/material";

import { deleteNetwork, getNetworks, whereObjectUsed } from "app/crud/networks.crud";
import { getWhereUsed } from "utils/utils";
import { successToast, errorToast, warningToast } from "utils/toastUtils";
import { ConfirmationPopup } from "components/ConfirmDialog";
import AddServiceModal from "components/services/AddServiceModal";
import BeSafeSearchBar from 'components/commons/BeSafeSearchBar';
import TableLoader from 'components/commons/TableLoader';
import NoDataDisplay from 'components/commons/NoDataDisplay';
import BeSafeContainer from 'components/commons/BeSafeContainer';
import BeSafeButton from 'components/commons/BeSafeButton';
import { Add, Delete, Edit } from '@mui/icons-material';

const ServiceObjects = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [whereUsed, setWhereUsed] = useState([]);

  useEffect(() => {
    whereObjectUsed(selectedService.id)
      .then((response) => {
        setWhereUsed(getWhereUsed(response.data));
      })
      .catch(() => { });
  }, [selectedService]);

  const intl = useIntl();

  const selectService = useCallback(
    (service) => {
      setPreviewLoading(true);
      setSelectedService(service);
      setPreviewLoading(false);
    },
    []
  );

  const fetchServices = useCallback(
    (q) => {
      setLoading(true);
      setPreviewLoading(true);
      getNetworks({ type: props?.type, q })
        .then((response) => {
          setServices(response.data.objects);
          if (response.data.objects.length) {
            selectService(response.data.objects[0]);
          }
        })
        .catch((error) => {
          errorToast({ body: "SERVICES.LOAD_ERROR", intl: intl });
        })
        .finally(() => {
          setLoading(false);
          setPreviewLoading(false);
        });
    },
    [intl, selectService, props?.type]
  );

  useEffect(() => {
    fetchServices(searchQuery);
  }, [fetchServices, searchQuery]);

  const showEditServiceModal = () => {
    setEditMode(true);
    setShowModal(true);
  };

  const showNewServiceModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const deleteServiceObject = () => {
    if (whereUsed.length > 0) {
      warningToast({
        body: "NETWORK_OBJECTS.ALREADY_IN_USE",
        intl: intl,
      });
    } else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "GENERAL.WARNING",
        }),
        description: intl.formatMessage({
          id: "GENERAL.CONFIRM_DELETE",
        }),
        okLabel: intl.formatMessage({
          id: "GENERAL.OK",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          deleteNetwork(selectedService.id)
            .then((response) => {
              let vals = services.filter((ng) => ng.id !== selectedService.id);
              setServices(vals);
              if (vals.length) selectService(vals[0]);
              successToast({
                body: "SERVICES.DELETE_SUCCESS",
                intl: intl,
              });
            })
            .catch((err) => {
              if (err.response.data.already_in_used) {
                errorToast({
                  body: "SERVICES.ALREADY_IN_USE",
                  intl: intl,
                });
              } else {
                errorToast({
                  body: err.response.data?.error,
                  intl: intl,
                });
              }
            });
        },
      });
    }
  };

  const onCreateService = () => {
    fetchServices(searchQuery);
    setShowModal(false);
  };

  const parsePorts = (service) => {
    switch (service.object_type) {
      case "tcp_service":
        return service.data?.portrange;
      case "udp_service":
        return service.data?.portrange;
      default:
        return "";
    }
  };

  return (
    <div className="d-flex obj-cus-h-35 py-2">
      <div className="d-flex card flex-column w-40 h-100 mr-3">
        <div className="d-flex justify-content-between pl-3">
          <BeSafeSearchBar
            onSearch={setSearchQuery}
          />
          <BeSafeButton
            variant="transparent"
            className="py-0 ml-auto px-1"
            onClick={showNewServiceModal}
            icon={<Add />}
            tooltip="GENERAL.ADD"
          />
        </div>
        <div className="flex-grow-1 overflow-auto">
          <TableLoader visible={loading} rows={10} />
          <NoDataDisplay visible={!loading && services.length <= 0} />
          <BeSafeContainer visible={!loading && services.length > 0}>
            <div className="d-flex flex-column h-100">
              <ListGroup className="flex-grow-1 overflow-auto">
                {services.map((service) => (
                  <ListGroup.Item
                    className="rounded-0"
                    active={service.id === selectedService.id}
                    key={service.id}
                    action
                    onClick={() => selectService(service)}
                  >
                    <LanOutlinedIcon className="mr-2" />
                    {service.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </BeSafeContainer>
        </div>
      </div>
      {previewLoading ? (
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      ) : services.length <= 0 ? (
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <Card className="w-60 h-100 mr-3">
          <Card.Header>
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                <LanOutlinedIcon className="mr-2" />
                {selectedService.name}
              </span>
                {selectedService.editable && (
                <div>
                  <BeSafeButton
                    variant="transparent"
                    className="p-0 px-1"
                    onClick={showEditServiceModal}
                    icon={<Edit className="icon-table-md" />}
                    tooltip="GENERAL.EDIT_SMALL"
                  />
                  <BeSafeButton
                    variant="transparent"
                    className="p-0 px-1"
                    onClick={deleteServiceObject}
                    icon={<Delete className="icon-table-md" />}
                    tooltip="GENERAL.DELETE_SMALL"
                  />
                </div>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            {["tcp_service", "udp_service"].includes(props.type) && (
              <>
                <div className="d-flex py-2">
                  <span className="font-weight-bold">
                    <FormattedMessage id="SERVICES.PORTS" />
                  </span>
                  <span>{`: ${parsePorts(selectedService)}`}</span>
                </div>
              </>
            )}
            {["icmp_service"].includes(props.type) && (
              <>
                <div className="d-flex py-2">
                  <span className="font-weight-bold">
                    <FormattedMessage id="SERVICES.ICMP_TYPE" />
                  </span>
                  <span>{`: ${selectedService.data?.icmp_type || "None"}`}</span>
                </div>
                <div className="d-flex py-2">
                  <span className="font-weight-bold">
                    <FormattedMessage id="SERVICES.ICMP_CODE" />
                  </span>
                  <span>{`: ${selectedService.data?.icmp_code || "None"}`}</span>
                </div>
              </>
            )}
            {["other_service"].includes(props.type) && (
              <>
                <div className="d-flex py-2">
                  <span className="font-weight-bold">
                    <FormattedMessage id="SERVICES.PROTOCOL_NUMBER" />
                  </span>
                  <span>{`: ${selectedService.data?.protocol_number}`}</span>
                </div>
              </>
            )}
            {selectedService?.besafe_groups?.length > 0 &&
            <>
              <div className="d-flex py-2">
                <span className="font-weight-bold">
                  <FormattedMessage id="OBJECT_EXPLORER.GROUPS" />
                </span>
              </div>
              <ListGroup>
                {selectedService?.besafe_groups?.map((group, index) => (
                  <ListGroup.Item
                    key={group.id}
                    className="d-flex justify-content-between py-0"
                  >
                    <span className="w-75 py-2">{group.name}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>}
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {whereUsed?.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                whereUsed?.map((r) => r).join(", ")
              )}
            </span>
          </Card.Footer>
        </Card>
      )}
      <AddServiceModal
        editMode={editMode}
        showModal={showModal}
        onHide={() => setShowModal(false)}
        onCreate={() => onCreateService()}
        onUpdate={() => onCreateService()}
        protocol={props.type}
        selectedService={selectedService}
      />
    </div>
  );
};

export default ServiceObjects