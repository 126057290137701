import React, { useEffect } from 'react';
import styles from './Loader.module.scss';

const Loader = () => {
  useEffect(() => {
    return () => {
      new Promise((resolve) => setTimeout(() => resolve(), 5000));
    };
  }, []);

  return (
    <div className={styles.Container + ' m-auto'}>
      <div className={styles.Spinner}>
        <div
          className={'spinner-border text-primary ' + styles.SpinnerEl}
          role="status"
        />
      </div>
    </div>
  );
};

export default Loader;
