import { Delete, Edit } from "@mui/icons-material";
import BeSafeButton from "components/commons/BeSafeButton";
import BeSafeContainer from "components/commons/BeSafeContainer";
import { Card, ListGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

const DlpObjectDetail = ({ selectedDlpObject, type, ...props }) => {
  const usedList = [];
  const intl = useIntl();

  return (
    <>
      <Card className="w-60 h-100 mr-3">
        <Card.Header>
          <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
            <span className="my-auto">{selectedDlpObject.name}</span>
            <BeSafeContainer
              visible={selectedDlpObject.editable}
            >
              <div>
                <BeSafeButton
                  variant="transparent"
                  className="pr-1"
                  onClick={props.onEdit}
                  icon={<Edit className="icon-table-md" />}
                  tooltip={intl.formatMessage({id: "GENERAL.EDIT_SMALL"})}
                />
                <BeSafeButton
                  variant="transparent"
                  className="px-2"
                  onClick={props.onDelete}
                  icon={<Delete className="icon-table-md" />}
                  tooltip={intl.formatMessage({id: "GENERAL.DELETE_SMALL"})}
                />
              </div>
            </BeSafeContainer>
          </Card.Title>
        </Card.Header>
        <Card.Body className="overflow-auto">
          <BeSafeContainer visible={selectedDlpObject.description}>
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="DLP_OBJECTS.DESCRIPTION" />
              </span>
              <span>{": " + selectedDlpObject.description}</span>
            </div>
          </BeSafeContainer>

          <BeSafeContainer
            visible={selectedDlpObject.target && selectedDlpObject.account_id}
          >
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="DLP_OBJECTS.EXPRESSION" />
              </span>
              <span>{`: ${selectedDlpObject.target}`}</span>
            </div>
          </BeSafeContainer>

          <BeSafeContainer
            visible={selectedDlpObject?.besafe_groups?.length > 0}
          >
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="OBJECT_EXPLORER.GROUPS" />
              </span>
            </div>
            <ListGroup>
              {selectedDlpObject?.besafe_groups?.map(
                (group, index) => (
                  <ListGroup.Item
                    key={group.id}
                    className="d-flex justify-content-between py-0"
                  >
                    <span className="w-75 py-2">{group.name}</span>
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </BeSafeContainer>
        </Card.Body>
        <Card.Footer className="obj-where-used">
          <p className="font-weight-bold my-0">
            <FormattedMessage id="GENERAL.WHERE_USED" />:
          </p>
          <span>
            {usedList?.length <= 0 ? (
              <FormattedMessage id="GENERAL.NOT_IN_USE" />
            ) : (
              usedList?.join(", ")
            )}
          </span>
        </Card.Footer>
      </Card>
    </>
  );
};

export default DlpObjectDetail;
