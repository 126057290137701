import React from "react";
import AddNetworkModal from "components/networks/AddNetworkModal";
import AddNetworkRangeModal from "components/networks/AddNetworkRangeModal";
import AddFQDNModal from "components/networks/AddFQDNModal";
import AddMACModal from "components/networks/AddMACModal";
import AddUserModal from "components/networks/AddUserModal";
import AddCasbTenantModal from "./AddCasbTenantModal";
import AddServiceModal from "components/services/AddServiceModal";
import NetworkFormModal from "./NetworkFormModal";
import AddDlpObjectModal from "components/object_explorer/dlp/AddDlpObjectModal";
import AddScheduleModal from "components/object_explorer/schedules/AddScheduleModal";
import AddDnsRecordModal from "components/object_explorer/dns_records/AddDnsRecordModal";

const AddObjectModal = (props) => {

  return (
    <>
      {props.type === "network" && (
        <AddNetworkModal
          editMode={props.editMode}
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          selectedNetwork={props.selectedObject}
        />
      )}
      {props.type === "network_range" && (
        <AddNetworkRangeModal
          editMode={props.editMode}
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          selectedNetwork={props.selectedObject}
        />
      )}
      {props.type === "fqdn" && (
        <AddFQDNModal
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
        />
      )}
      {props.type === "mac" && (
        <AddMACModal
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
        />
      )}
      {props.type === "user" && (
        <AddUserModal
          editMode={props.editMode}
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          selectedNetwork={props.selectedObject}
        />
      )}
      {props.type === "casb_tenant" && (
        <AddCasbTenantModal
          editMode={props.editMode}
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          selectedNetwork={props.selectedObject}
        />
      )}
      {(props.type === "tcp_service" || props.type === "udp_service" || props.type === "icmp_service" || props.type === "other_service") && (
        <AddServiceModal
          editMode={props.editMode}
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          protocol={props.type}
          selectedService={props.selectedObject}
        />
      )}
      { props.type.endsWith("-group") && (
        <NetworkFormModal
          objectType={props.type.replace("-group", "")}
          showModal={props.showModal}
          editMode={props.editMode}
          onHide={props.onHide}
          parentCallback={props.onUpdate}
          networkGroup={props.selectedObject}
          networkList={props.selectedObject.besafe_objects}
          selectType={props.selectType}
        />
      )}
      { props.type.startsWith("dlp_") && (
        <AddDlpObjectModal
          editMode={props.editMode}
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          selectedDlpObject={props.selectedObject}
          type={props.type}
        />
      )}
      { props.type === "schedule" && (
        <AddScheduleModal
          editMode={props.editMode}
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          selectedSchedule={props.selectedObject}
        />
      )}
      { props.type === "dns_record" && (
        <AddDnsRecordModal
          editMode={props.editMode}
          showModal={props.showModal}
          onHide={props.onHide}
          onCreate={props.onCreate}
          onUpdate={props.onUpdate}
          selectedDnsRecord={props.selectedObject}
        />
      )}
    </>
  );
};

export default AddObjectModal;
