import React from 'react';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import Loader from './shared/Loader';
import Layout from './shared/layouts/Layout';

export const DefaultLayout = Layout;
export const defaultRoute = '/';
export const adminDefaultRoute = '/';
export const titleTemplate = '%s - beSafe Pro';

const lazy = (cb) =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

export const routes = [
  {
    path: '/',
    component: lazy(() => import('./components/pages/Dashboard')),
    roles: ['super_admin', 'account_manager', 'end_user', 'operator'],
  },
  {
    path: '/profile',
    component: lazy(() => import('./components/pages/Profile')),
    roles: ['super_admin', 'account_manager', 'end_user', 'operator'],
  },
  {
    path: '/administration',
    component: lazy(() => import('./components/pages/Administration')),
    roles: ['super_admin', 'account_manager', 'end_user'],
  },
  {
    path: '/reports',
    component: lazy(() => import('./components/pages/LogsReports')),
    roles: ['super_admin', 'account_manager', 'end_user', 'operator'],
  },
  {
    path: '/devices',
    component: lazy(() => import('./components/pages/Devices')),
    roles: ['super_admin', 'account_manager', 'end_user'],
  },
  {
    path: '/register-device',
    component: lazy(() => import('./components/pages/RegisterDevice')),
    roles: ['super_admin', 'account_manager', 'end_user'],
  },
  {
    path: '/device-info/*',
    component: lazy(() => import('./components/pages/DeviceInfo')),
    roles: ['super_admin', 'account_manager', 'end_user'],
  },
  // Uncomment when XDR is activated
  // {
  //   path: "/xdr",
  //   component: lazy(() => import("./components/pages/Xdr")),
  //   roles: ["account_manager", "end_user"]
  // },
  {
    path: '/configuration/:functionality/:id',
    component: lazy(() =>
      import('./components/configuration/AdvanceConfiguration')
    ),
    roles: ['account_manager', 'end_user'],
  },
];
