import React from "react";

const BeSafeControlFeedback = (props) => {

  return (
    <div className="d-block invalid-feedback">
      {props.children}
    </div>
  );
};

export default BeSafeControlFeedback;
