import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionTypes = {
  SetThreatTaskId: "[Change Threat Profile Task Id] Action",
  SetThreatSectionLock: "[Change Threat Profile Section Status] Action",
};

const initialState = {
  threatProfileTaskId: "",
  isThreatProfilesLocked: false,
};

export const reducer = persistReducer(
  { storage, key: "threat_profiles" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetThreatTaskId:
        return {
          ...state,
          threatProfileTaskId: action.payload.threatProfileTaskId,
        };
      case actionTypes.SetThreatSectionLock:
        return {
          ...state,
          isThreatProfilesLocked: action.payload.isThreatProfilesLocked,
        };
      default:
        return state;
    }
  }
);

export const SetThreatTaskId = (threatProfileTaskId) => ({
  type: actionTypes.SetThreatTaskId,
  payload: { threatProfileTaskId },
});

export const SetThreatSectionLock = (isThreatProfilesLocked) => ({
  type: actionTypes.SetThreatSectionLock,
  payload: { isThreatProfilesLocked },
});
