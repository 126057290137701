import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
} from '@material-ui/core';
import { render } from 'react-dom';
import { Form } from 'react-bootstrap';
import 'assets/scss/confirm_dialog.scss';

import BeSafeContainer from 'components/commons/BeSafeContainer';
import BeSafeButton from 'components/commons/BeSafeButton';

const Transition3 = React.forwardRef(function Transition5(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmDialog(props) {
  const [showDialog, setDialog] = useState(true);

  const okAction = () => {
    setDialog(false);
    const params = props.checkboxes?.reduce((acc, checkbox) => {
      const obj = {};
      obj[checkbox.field] = checkbox.checked;
      return Object.assign(acc, obj);
    }, {});

    props.okAction(params);
  };

  const closeAction = () => {
    setDialog(false);
    props.closeAction();
  };

  const confirmAction = () => {
    setDialog(false);
    props.confirmAction();
  };

  const fourthAction = () => {
    setDialog(false);
    props.fourthAction();
  };

  const onClose = () => {
    closeAction();
  };

  return (
    <div className="react-confirm-popup">
      <Dialog
        classes={{ root: 'confirm-popup-container' }}
        open={showDialog}
        TransitionComponent={Transition3}
        keepMounted
        onClose={onClose}
        maxWidth={props.maxWidth}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <BeSafeContainer visible={props.description}>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="mb-1"
            >
              {props.description}
            </DialogContentText>
          </DialogContent>
        </BeSafeContainer>
        <BeSafeContainer visible={props.checkboxes}>
          {props.checkboxes.map((checkbox) => {
            return (
              <DialogContent className="pt-0">
                <Form.Check
                  type="checkbox"
                  name="checkbox"
                  onChange={() => (checkbox.checked = !checkbox.checked)}
                  value={checkbox.checked}
                  checked={checkbox.checked}
                  className="d-inline"
                />
                <DialogContentText
                  id="alert-dialog-slide-description"
                  className="ml-1 d-inline"
                >
                  {checkbox.title}
                </DialogContentText>
              </DialogContent>
            );
          })}
        </BeSafeContainer>
        <BeSafeContainer>
          <DialogActions>
            {props.cancelLabel && (
              <BeSafeButton
                className="mb-1"
                variant="outline-danger"
                onClick={closeAction}
                text={props.cancelLabel}
                size="sm"
              />
            )}
            {props.fourthLabel && (
              <BeSafeButton
                className="mb-1"
                variant="primary"
                onClick={fourthAction}
                text={props.fourthLabel}
                size="sm"
              />
            )}
            {props.confirmLabel && (
              <BeSafeButton
                className="mb-1"
                variant="outline-primary"
                onClick={confirmAction}
                text={props.confirmLabel}
                size="sm"
              />
            )}
            <BeSafeButton
              className="mb-1 mr-2"
              variant="outline-primary"
              onClick={okAction}
              text={props.okLabel}
              size="sm"
            />
          </DialogActions>
        </BeSafeContainer>
      </Dialog>
    </div>
  );
}

ConfirmDialog.defaultProps = {
  title: 'Title',
  okLabel: 'Agree',
  okTitle: '',
  closeAction: () => null,
  okAction: () => null,
  confirmAction: () => null,
  fourthAction: () => null,
  checkboxes: [],
  maxWidth: 'xs',
};

export function ConfirmationPopup(props) {
  let divTarget = document.getElementById('react-confirm-popup');
  if (!divTarget) {
    // Mount the ReactConfirmAlert component
    document.body.children[0].classList.add('react-confirm-popup-blur');
    divTarget = document.createElement('div');
    divTarget.id = 'react-confirm-popup';
    document.body.appendChild(divTarget);
  }
  render(<ConfirmDialog key={new Date()} {...props} />, divTarget);
}
