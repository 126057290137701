import { CircularProgress } from "@mui/material";
import { Card, ListGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { getWhereUsed } from "utils/utils";
import DnsIcon from "@mui/icons-material/Dns";
import { useEffect } from "react";
import { useState } from "react";
import { whereObjectUsed } from "app/crud/networks.crud";
import { Delete } from "@mui/icons-material";
import BeSafeButton from "components/commons/BeSafeButton";

const FQDNSubList = ({
  previewLoading,
  fqdns,
  selectedFQDN,
  deleteNetworkObject,
}) => {

  const [whereUsed, setWhereUsed] = useState([]);

  useEffect(() => {
    whereObjectUsed(selectedFQDN.id)
      .then((response) => {
        setWhereUsed(getWhereUsed(response.data));
      })
      .catch(() => { });
  }, [selectedFQDN.id]);

  return (
    <>
      {previewLoading ? (
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      ) : fqdns.length <= 0 ? (
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <Card className="w-60 h-100 mr-3">
          <Card.Header>
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                <DnsIcon className="mr-2" />
                {selectedFQDN.name}
              </span>
              {selectedFQDN.editable && (
                <div>
                  <BeSafeButton
                    variant="transparent"
                    className="px-1 py-0"
                    onClick={deleteNetworkObject}
                    icon={<Delete className="icon-table-md" />}
                    tooltip="GENERAL.DELETE_SMALL"
                  />
                </div>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="GENERAL.DESCRIPTION" />
              </span>
              <span>{`: ${selectedFQDN?.description || ""}`}</span>
            </div>
            {selectedFQDN?.data?.ips?.length > 0 &&
              <>
                <div className="d-flex py-2">
                  <span className="font-weight-bold">
                    <FormattedMessage id="NETWORK_OBJECTS.IPS" />
                  </span>
                </div>
                <ListGroup>
                  {selectedFQDN?.data?.ips?.map((g, index) => (
                    <ListGroup.Item
                      key={"ips" + index}
                      className="d-flex justify-content-between py-0"
                    >
                      <span className="w-75 py-2">{g}</span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>}
            {selectedFQDN?.besafe_groups?.length > 0 &&
            <>
              <div className="d-flex py-2">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_OBJECTS.GROUPS" />
                </span>
              </div>
              <ListGroup>
                {selectedFQDN?.besafe_groups?.map((g, index) => (
                  <ListGroup.Item
                    key={g.id}
                    className="d-flex justify-content-between py-0"
                  >
                    <span className="w-75 py-2">{g.name}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>}
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {whereUsed?.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                whereUsed?.map((r) => r).join(", ")
              )}
            </span>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default FQDNSubList;
