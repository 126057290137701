import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";

import { createNetwork, updateNetwork } from "app/crud/networks.crud";
import { successToast, errorToast } from "utils/toastUtils";
import BeSafeButton from 'components/commons/BeSafeButton';

const AddNetworkModal = ({
  editMode,
  selectedNetwork,
  showModal,
  onHide,
  onCreate,
  onUpdate,
}) => {
  const intl = useIntl();

  const initialNetworkValues = {
    name: editMode ? selectedNetwork?.name : "",
    description: editMode ? selectedNetwork?.description : "",
    type: editMode ? selectedNetwork?.data?.network_type : "lan",
    network: editMode ? selectedNetwork?.ip_address : "",
    networkIPv6: editMode ? selectedNetwork?.ipv6_address : "",
  };

  return (
    <Modal show={showModal} onHide={onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>{editMode ?
          <FormattedMessage id="NETWORK_OBJECTS.EDIT" /> :
          <FormattedMessage id="NETWORK_OBJECTS.ADD" />
        }</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          ...initialNetworkValues,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          let params = {
            name: values.name,
            description: values.description,
            network_type: values.type,
            ip_address: values.network,
            ipv6_address: values.networkIPv6,
            type: "network",
          };

          if (editMode) {
            updateNetwork({ ...params, id: selectedNetwork.id })
              .then((response) => {
                onUpdate(params);
                successToast({
                  body: "NETWORK_OBJECTS.EDIT_SUCCESS",
                  intl: intl,
                });
              })
              .catch((err) => {
                errorToast({ body: err.response.data?.error, intl: intl });
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            createNetwork(params)
              .then((response) => {
                onCreate();
                successToast({
                  body: "NETWORK_OBJECTS.ADD_SUCCESS",
                  intl: intl,
                });
              })
              .catch((err) => {
                errorToast({ body: err.response.data?.error, intl: intl });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }}
        validate={(values) => {
          const errors = {
          };

          if (!values.name || !values.name.trim()) {
            errors.name = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD", });
          }

          if (values.name.length > 64) {
            errors.name = intl.formatMessage({
              id: "GENERAL.MAX_NAME_SIZE",
            });
          }

          if ((!values.network || !values.network.trim()) && (!values.networkIPv6 || !values.networkIPv6.trim())) {
            errors.network = intl.formatMessage({ id: "NETWORK_OBJECTS.FIELD_REQUIRED", });
            errors.networkIPv6 = intl.formatMessage({ id: "NETWORK_OBJECTS.FIELD_REQUIRED", });
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="name">
                <Form.Label>
                  <FormattedMessage id="GENERAL.NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  isInvalid={errors.name && touched.name}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "GENERAL.NAME_PLACEHOLDER",
                  })}
                  value={values.name}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>
                  <FormattedMessage id="GENERAL.DESCRIPTION" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  onChange={handleChange}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "GENERAL.DESCRIPTION_PLACEHOLDER",
                  })}
                  value={values.description}
                />
              </Form.Group>
              <Form.Group controlId="network">
                <Form.Label>
                  <FormattedMessage id="OBJECT_EXPLORER.NETWORK_IPV4" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="network"
                  onChange={handleChange}
                  isInvalid={errors.network}
                  className="border-secondary"
                  placeholder="2.2.2.2/32"
                  value={values.network}
                />
                {errors.network && touched.network && (
                  <Form.Control.Feedback type="invalid">
                    {errors.network}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="networkIPv6">
                <Form.Label>
                  <FormattedMessage id="OBJECT_EXPLORER.NETWORK_IPV6" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="networkIPv6"
                  onChange={handleChange}
                  isInvalid={errors.networkIPv6}
                  className="border-secondary"
                  placeholder="::2/128"
                  value={values.networkIPv6}
                />
                {errors.networkIPv6 && touched.networkIPv6 && (
                  <Form.Control.Feedback type="invalid">
                    {errors.networkIPv6}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="align-items-baseline ml-2 mt-4">
                <span>
                  <Form.Check
                    inline
                    type="radio"
                    id="lan"
                    label="LAN"
                    name="lan"
                    value="lan"
                    className="checkbox-cursor"
                    onChange={() => setFieldValue("type", "lan")}
                    custom
                    checked={values.type === "lan"}
                  />
                </span>
                <span>
                  <Form.Check
                    inline
                    type="radio"
                    id="no-lan"
                    label="No LAN"
                    name="no-lan"
                    value="no-lan"
                    className="checkbox-cursor"
                    onChange={() => setFieldValue("type", "no-lan")}
                    custom
                    checked={values.type === "no-lan"}
                  />
                </span>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <BeSafeButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                text={<FormattedMessage id="GENERAL.SAVE" />}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddNetworkModal;
