import { Check, KeyboardArrowDown, KeyboardArrowRight, ViewColumn } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import "assets/scss/resizable_table.scss";
import { useRef } from "react";
import SkeletonLoader from "tiny-skeleton-loader-react";
import BeSafeColumn from "./BeSafeColumn";
import { FormattedMessage } from "react-intl";

const BeSafeTable = ({ rows, toggleColumns, resizable, stickyHeaders, loading, loadingRows, loadingMore, sections, className, conditionalRowClassname, onRowClick, children }) => {
  const [visibility, setVisibility] = useState(React.Children.toArray(children).map((child) => child?.props.visible || false));
  const [sectionVisibility, setSectionVisibility] = useState(sections.map((section) => section.visible));
  const [tableInitialWidth, setTableInitialWidth] = useState(0);
  const table = useRef(null);

  useEffect(() => {
    if (visibility.length !== React.Children.toArray(children).length) {
      setVisibility(React.Children.toArray(children).map((child) => child?.props.visible || false));
    }

  }, [visibility.length, children]);

  useEffect(() => {
    if (sections) {
      setSectionVisibility(sections.map((section) => section.visible));
    }
  }, [sections]);

  const renderHeaders = () => {
    return React.Children.toArray(children).map((child, index) => {
      return visibility[index] && child ? React.cloneElement(child, {
        renderType: 'header',
        resizable: resizable,
        onResize: onResize,
        onStartResize: onStartResize,
        headerClassName: child?.props.headerClassName + (stickyHeaders ? " sticky-headers" : "") }) : null;
    });
  };

  const renderBody = () => {
    if (loading) {
      return [...Array(loadingRows)].map(() => (
        <tr>
          {React.Children.map(children, (_child, index) => {
            return visibility[index] ? <td><SkeletonLoader width="100%" height={26.5}/></td> : null;
          })}
          {toggleColumns && <td></td>}
        </tr>
      ));
    }

    if (rows && rows.length === 0) {
      return <tr>
        <td className="text-center" colSpan={React.Children.count(children) + (toggleColumns ? 1 : 0)}><p><FormattedMessage id="GENERAL.NO_DATA" /></p></td>
      </tr>;
    }

    if (sections.length > 0) {
      return sections.map((section, index) => {
        if (section.rows.length > 0) {
          return <>
            <tr className={"table-section"}>
              <td className="align-middle" colSpan={1}>
                <div className="cursor-pointer text-left" onClick={() => toggleSectionVisibility(index)} >
                  {sectionVisibility[index] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </div>
              </td>
              <td className="align-middle" colSpan={visibility.filter((visible) => visible).length - 1 + (toggleColumns ? 1 : 0)}>
                <span>
                  {section.title}
                </span>
              </td>
            </tr>
            {sectionVisibility[index] && section.rows.map((data, row) => (
              <tr className={conditionalRowClassname(data)}>
                {React.Children.map(children, (child, index) => {
                  return visibility[index] && child ? React.cloneElement(child, { renderType: 'cell', data: data, row: row }) : null;
                })}
                {toggleColumns && <td></td>}
              </tr>
            ))}
          </>
        }
        return null;
      })
    }

    return <>{rows.map((data, row) => (
        <tr className={(onRowClick ? "cursor-pointer " : "") + conditionalRowClassname(data)} onClick={() => onRowClick ? onRowClick(data) : false}>
          {React.Children.toArray(children).map((child, index) => {
            return visibility[index] && child ? React.cloneElement(child, { renderType: 'cell', data: data, row: row }) : null;
          })}
          {toggleColumns && <td></td>}
        </tr>
      ))}
      {loadingMore && <tr>
          <td className="text-center" colSpan={React.Children.count(children) + (toggleColumns ? 1 : 0)}><h5><FormattedMessage id="GENERAL.LOADING_MORE" /></h5></td>
      </tr>}
    </>
  };

  const onStartResize = () => {
    setTableInitialWidth(table.current.scrollWidth);
  };

  const onResize = (sizeChange) => {
    table.current.style.width = `${tableInitialWidth + (sizeChange)}px`;
  };

  const toggleVisibility = (index) => {
    const newVisibility = [...visibility];
    newVisibility[index] = !visibility[index];

    setVisibility(newVisibility);
  };

  const toggleSectionVisibility = (index) => {
    const newVisibility = [...sectionVisibility];
    newVisibility[index] = !sectionVisibility[index];

    setSectionVisibility(newVisibility);
  };

  const renderToggle = () => {
    return <DropdownButton
      variant="outline-primary"
      size="sm"
      title={<ViewColumn />}
    >
      {React.Children.toArray(children).map((child, index) => {
        return child && <Dropdown.Item
          href="#"
          onClick={(e) => toggleVisibility(index)}
          key={`show-col-${index}`}
        >
          <div className="d-flex justify-content-between">
            <span>{child?.props.header}</span>
            {visibility[index] && <Check className="icon-medium ml-3" />}
          </div>
        </Dropdown.Item>
      })}
    </DropdownButton>
  };

  return (
    <Table ref={table} className={(resizable ? "resizable-table " : "") + "mb-0 table-header " + className} hover>
      <thead>
        <tr className={resizable ? "resize-headers" : ""}>
          {renderHeaders()}
          {toggleColumns && <th className={"visibility-column" + (stickyHeaders ? " sticky-headers" : "")}>{renderToggle()}</th>}
        </tr>
      </thead>
      <tbody>
        {renderBody()}
      </tbody>
    </Table>
  );
};

BeSafeTable.defaultProps = {
  loadingRows: 5,
  sections: [],
  conditionalRowClassname: () => ''
};

BeSafeTable.Column = BeSafeColumn;

export default BeSafeTable;
