import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';

import { logout } from 'app/crud/auth.crud';
import Loader from 'shared/Loader';
import * as auth from 'store/ducks/auth.duck';
import { SetPolicyChanges } from 'store/ducks/policyChange.duck';

const Logout = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    logout()
      .then(() => {
        dispatch(auth.actions.logout());
        dispatch(SetPolicyChanges(0));

        if (keycloak.token) {
          document.cookie =
            'kc_session =' +
            keycloak.token +
            '=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          keycloak.logout({ redirectUri: '/' });
        }
      })
      .catch(() => {
        dispatch(auth.actions.logout());
        dispatch(SetPolicyChanges(0));
      });
  });

  return <Loader />;
};

export default Logout;
