import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import BeSafeTooltip from "./BeSafeTooltip";

const BeSafeButton = ({ 
  visible,
  tooltip, 
  variant, 
  type, 
  className,
  onClick,
  disabled,
  href,
  target,
  style,
  icon,
  text,
  size }) => {

  return (
    visible ?
      <BeSafeTooltip
        tooltip={tooltip ? <FormattedMessage id={tooltip} /> : ""}
      >
        <Button
          variant={variant}
          type={type}
          className={className}
          onClick={onClick}
          disabled={disabled}
          href={href}
          target={target}
          style={style}
          size={size}
        >
          {icon}
          {text}
        </Button>
      </BeSafeTooltip>
      : null
  );
};

BeSafeButton.defaultProps = {
  visible: true,
  type: "button",
};
export default BeSafeButton;
