import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const ObjectViewer = props => {
  useEffect(() => { }, [props])

  return (
    <>
      {props.type === 'update' && (
        <Table
        >
          <thead>
            <tr key='header' style={{ backgroundColor: 'rgb(245 245 245)' }}>
              <th>
                <div className='d-flex '>
                  <FormattedMessage id='GENERAL.PROPERTY' />
                </div>
              </th>
              <th>
                <div className='d-flex '>
                  <FormattedMessage id='GENERAL.PREVIOUS' />
                </div>
              </th>
              <th>
                <div className='d-flex '>
                  <FormattedMessage id='GENERAL.PENDING' />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className=''>
            {Object.keys(props.object).map((element, index) => {
              return (
                <React.Fragment key={index}>
                  <tr key={index}>
                    <td>{element}</td>
                    <td>{String(props.object[element][0])}</td>
                    <td>{String(props.object[element][1])}</td>
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default ObjectViewer
