import React, { useState, useEffect, useCallback } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import {
  deleteNetworksGroup,
  getNetworksGroups,
  updateNetworksGroup,
} from "app/crud/networks.crud";
import { objectDatas } from "utils/utils";
import { successToast, errorToast } from "utils/toastUtils";
import NetworkFormModal from "./NetworkFormModal";
import { ConfirmationPopup } from "components/ConfirmDialog";
import BeSafeSearchBar from "components/commons/BeSafeSearchBar";
import TableLoader from "components/commons/TableLoader";
import NoDataDisplay from "components/commons/NoDataDisplay";
import BeSafeContainer from "components/commons/BeSafeContainer";
import { Add, Delete, Edit, Remove } from "@mui/icons-material";
import BeSafeButton from "components/commons/BeSafeButton";

const NetworkObjects = (props) => {
  const [networks, setNetworks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedNetwork, setSelectedNetwork] = useState({});
  const [networkList, setNetworkList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const intl = useIntl();

  const selectNetwork = useCallback(
    (network) => {
      setSelectedNetwork(network);
      setNetworkList(network.besafe_objects);
    }, []
  );

  const fetchNetworks = useCallback(
    (q) => {
      setLoading(true);
      setPreviewLoading(true);
      getNetworksGroups({ type: props?.type, q })
        .then((response) => {
          setNetworks(response.data.objects);
          if (response.data.objects.length) {
            selectNetwork(response.data.objects[0]);
            setNetworkList(response.data.objects[0].besafe_objects);
          }
        })
        .catch((error) => {
          errorToast({ body: "NETWORK_OBJECTS.LOAD_ERROR", intl: intl });
        })
        .finally(() => {
          setLoading(false);
          setPreviewLoading(false);
        });
    },
    [intl, selectNetwork, props?.type]
  );

  useEffect(() => {
    fetchNetworks(searchQuery);
  }, [fetchNetworks, searchQuery]);

  const editNetworkGroup = () => {
    setEditMode(true);
    setShowModal(true);
  };

  const deleteNetworkGroup = () => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        deleteNetworksGroup(selectedNetwork.id)
          .then((response) => {
            let vals = networks.filter((ng) => ng.id !== selectedNetwork.id);
            setNetworks(vals);
            if (vals.length) selectNetwork(vals[0]);
            successToast({
              body: "NETWORK_OBJECTS.GROUP_DELETE_SUCCESS",
              intl: intl,
            });
          })
          .catch((err) => {
            if (err.response.data.already_in_used) {
              errorToast({
                body: "NETWORK_OBJECTS.GROUP_ALREADY_IN_USE",
                intl: intl,
              });
            } else if (err.response?.data?.error !== "cancelled") {
              errorToast({
                body: err.response.data?.error,
                intl: intl,
              });
            }
          });
      },
    });
  };

  const showNewNetworkGroupModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const detachNetworkObject = (index) => {
    let values = [...networkList];
    values.splice(index, 1);

    let params = {
      id: selectedNetwork.id,
      name: selectedNetwork.name,
      ip_protocol: selectedNetwork.ip_protocol,
      objects_ids: values.map((v) => v.id),
      type: props.type,
    };

    updateNetworksGroup(params)
      .then((response) => {
        onCreateNetworkGroup();
        successToast({ body: "NETWORK_OBJECTS.GROUP_EDIT_SUCCESS", intl: intl });
      })
      .catch((err) => {
        errorToast({
          body: err.response?.data?.error
            ? err.response.data.error : "NETWORK_OBJECTS.GROUP_EDIT_ERROR",
          intl: intl
        });
      })
      .finally(setSubmitting(false));
  };

  const onCreateNetworkGroup = () => {
    fetchNetworks(searchQuery);
    setShowModal(false);
  };

  return (
    <div className="d-flex obj-cus-h-35 py-2">
      <div className="d-flex card flex-column w-40 h-100 mr-3">
        <div className="d-flex justify-content-between pl-3">
          <BeSafeSearchBar
            onSearch={setSearchQuery}
          />
          <BeSafeButton
            variant="transparent"
            className="py-0 ml-auto px-1"
            onClick={showNewNetworkGroupModal}
            icon={<Add />}
            tooltip="GENERAL.ADD"
          />
        </div>
        <div className="flex-grow-1 overflow-auto">
          <TableLoader visible={loading} rows={10} />
          <NoDataDisplay visible={!loading && networks.length <= 0} />
          <BeSafeContainer visible={!loading && networks.length > 0}>
            <div className="d-flex flex-column h-100">
              <ListGroup className="flex-grow-1 overflow-auto">
                {networks.map((network) => (
                  <ListGroup.Item
                    className="rounded-0"
                    active={network.id === selectedNetwork.id}
                    key={network.id}
                    action
                    onClick={() => selectNetwork(network)}
                  >
                    <i className={`${objectDatas["group"].icon} mr-2`}></i>
                    {network.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </BeSafeContainer>
        </div>
      </div>

      {previewLoading ? (
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr-3">
          <CircularProgress />
        </div>
      ) : networks.length <= 0 ? (
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <Card className="w-60 h-100 mr-3">
          <Card.Header>
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                <i
                  className={`${objectDatas["group"].icon} custom-header-pad mr-2`}
                ></i>
                {selectedNetwork.name}
              </span>
              {selectedNetwork.editable && (
                <div>
                  <BeSafeButton
                    variant="transparent"
                    className="px-1 py-0"
                    onClick={editNetworkGroup}
                    icon={<Edit className="icon-table-md" />}
                    tooltip="GENERAL.EDIT_SMALL"
                  />
                  <BeSafeButton
                    variant="transparent"
                    className="px-1 py-0"
                    disabled={isSubmitting}
                    onClick={deleteNetworkGroup}
                    icon={<Delete className="icon-table-md" />}
                    tooltip="GENERAL.DELETE_SMALL"
                  />
                </div>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
                {selectedNetwork.group_type.match(/^(network|network_range|user)$/) &&
              <div className="d-flex py-2">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_OBJECTS.PROTOCOL" />
                </span>
                <span>
                  {`: ${selectedNetwork.ip_protocol !== null ?
                    intl.formatMessage(
                      { id: `NETWORK_OBJECTS.${selectedNetwork.ip_protocol.toUpperCase()}` }
                    ) : ""}`
                  }
                </span>
              </div>
            }
            <div className="d-flex justify-content-between font-weight-bold">
              <FormattedMessage id="OBJECT_EXPLORER.OBJECT_LIST" />
              {selectedNetwork.editable &&
                <BeSafeButton
                  variant="transparent"
                  className="p-0 mr-3"
                  onClick={editNetworkGroup}
                  icon={<Add />}
                    tooltip="OBJECT_MODAL.ADD"
                />
              }
            </div>
            <ListGroup>
              {networkList.map((nl, index) => (
                <ListGroup.Item
                  key={nl.id}
                  className="d-flex justify-content-between py-0"
                >
                  <span className="w-75 py-2">{nl.name}</span>
                  {selectedNetwork.editable && (
                      <div className="my-auto">
                        <BeSafeButton
                          variant="transparent"
                          className="p-0"
                          disabled={isSubmitting}
                          onClick={() => detachNetworkObject(index)}
                          icon={<Remove />}
                          tooltip="GENERAL.DELETE_SMALL"
                        />
                      </div>
                    )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {selectedNetwork?.firewall_rules?.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                selectedNetwork.firewall_rules
                  .map((item) => item.name)
                  .join(", ")
              )}
            </span>
          </Card.Footer>
        </Card>
      )}
      <NetworkFormModal
        objectType={props.type}
        showModal={showModal}
        onHide={() => setShowModal(false)}
        parentCallback={onCreateNetworkGroup}
        networkGroup={selectedNetwork}
        networkList={networkList}
        editMode={editMode}
        selectType={props.type}
      />
    </div>
  );
};

export default NetworkObjects;
