import React, { useState } from 'react';
import { Modal, Tab, Row, Col, Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import NetworkObjects from 'components/networks/NetworkObjects';
import ServiceObjects from 'components/services/ServiceObjects';
import NetworkObjectList from 'components/networks/NetworkObjectList';
import ScheduleObjectList from 'components/object_explorer/schedules/ScheduleObjectList';
import DlpObjectList from 'components/object_explorer/dlp/DlpObjectList';
import DnsRecordObjectList from 'components/object_explorer/dns_records/DnsRecordObjectList';

const ObjectExplorerModal = (props) => {
  const from = useLocation().pathname.split("/").pop();

  const [isNetworkMenuExpanded, setNetworkMenuExpanded] = useState(from !== "dlp");
  const [isGroupMenuExpanded, setGroupMenuExpanded] = useState(false);
  const [isServicesExpanded, setServicesExpanded] = useState(false);
  const [isDlpExpanded, setDlpExpanded] = useState(from === "dlp");

  const hideModal = () => {
    setServicesExpanded(false);
    setNetworkMenuExpanded(true);
    props.hideModal();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={hideModal}
      centered
      scrollable
      className="object-explorer"
      dialogClassName="vw-90"
    >
      <Modal.Header className="py-3 border border-0 bg-light-grey" closeButton>
        <Modal.Title>
          <FormattedMessage id="NAVBAR.OBJECT_EXPLORER" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="obj-cus-h-35 p-0 bg-light-grey">
        <Tab.Container id="left-tabs-example" defaultActiveKey="network">
          <Row className="obj-cus-h-35 mx-0">
            <Col md={3} sm={12} className="obj-cus-h-35 m-0 px-3 py-2">
              <div className="card h-100 overflow-auto">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <div className="border-bottom">
                      <Nav.Link
                        className="pl-2 mr-0 d-flex justify-content-between align-items-baseline"
                        onClick={() =>
                          setNetworkMenuExpanded(!isNetworkMenuExpanded)
                        }
                      >
                        <FormattedMessage id="OBJECT_EXPLORER.NETWORK_ELEMENTS" />
                        {isNetworkMenuExpanded && <KeyboardArrowUpIcon />}

                        {!isNetworkMenuExpanded && <KeyboardArrowDownIcon />}
                      </Nav.Link>
                      <div
                        className={isNetworkMenuExpanded ? "d-block" : "d-none"}
                      >
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="network"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.NETWORKS" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="network_range"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.NETWORK_RANGE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="fqdn"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.FQDN" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="geolocation"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.GEOLOCATION" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="mac"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.MAC" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="user"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.USERS" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="casb_tenant"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.CASB_TENANTS" />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <div className="border-bottom">
                      <Nav.Link
                        className="pl-2 mr-0 d-flex justify-content-between align-items-baseline"
                        onClick={() => setServicesExpanded(!isServicesExpanded)}
                      >
                        <FormattedMessage id="OBJECT_EXPLORER.SERVICES" />
                        {isServicesExpanded && <KeyboardArrowUpIcon />}

                        {!isServicesExpanded && <KeyboardArrowDownIcon />}
                      </Nav.Link>
                      <div
                        className={isServicesExpanded ? "d-block" : "d-none"}
                      >
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="service-objects-tcp"
                        >
                          <FormattedMessage id="SERVICES.TCP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="service-objects-udp"
                        >
                          <FormattedMessage id="SERVICES.UDP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="service-objects-icmp"
                        >
                          <FormattedMessage id="SERVICES.ICMP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="service-objects-others"
                        >
                          <FormattedMessage id="SERVICES.OTHER_SERVICE" />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <div className="border-bottom">
                      <Nav.Link
                        className="pl-2 mr-0 d-flex justify-content-between align-items-baseline"
                        onClick={() => setDlpExpanded(!isDlpExpanded)}
                      >
                        <FormattedMessage id="OBJECT_EXPLORER.DLP" />
                        {isDlpExpanded && <KeyboardArrowUpIcon />}

                        {!isDlpExpanded && <KeyboardArrowDownIcon />}
                      </Nav.Link>
                      <div className={isDlpExpanded ? "d-block" : "d-none"}>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="dlp_file_types"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.FILE_TYPES" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="dlp_file_names"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.FILE_NAMES" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="dlp_contents"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.CONTENTS" />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <div className="border-bottom">
                      <Nav.Link
                        className="pl-2 mr-0 d-flex justify-content-between align-items-baseline"
                        onClick={() =>
                          setGroupMenuExpanded(!isGroupMenuExpanded)
                        }
                      >
                        <FormattedMessage id="OBJECT_EXPLORER.GROUPS" />
                        {isGroupMenuExpanded && <KeyboardArrowUpIcon />}

                        {!isGroupMenuExpanded && <KeyboardArrowDownIcon />}
                      </Nav.Link>
                      <div
                        className={isGroupMenuExpanded ? "d-block" : "d-none"}
                      >
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="network-group"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.NETWORKS" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="network_range-group"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.NETWORK_RANGE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="fqdn-group"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.FQDN" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="geolocation-group"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.GEOLOCATION" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="mac-group"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.MAC" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="user-group"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.USERS" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="tcp_service-group"
                        >
                          <FormattedMessage id="SERVICES.TCP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="udp_service-group"
                        >
                          <FormattedMessage id="SERVICES.UDP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="icmp_service-group"
                        >
                          <FormattedMessage id="SERVICES.ICMP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="other_service-group"
                        >
                          <FormattedMessage id="SERVICES.OTHER_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="dlp_file_types-group"
                        >
                          <FormattedMessage id="DLP.FILE_TYPES" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="dlp_file_names-group"
                        >
                          <FormattedMessage id="DLP.FILE_NAMES" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="dlp_content-group"
                        >
                          <FormattedMessage id="DLP.CONTENT" />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="pl-2 border-bottom mr-0"
                      eventKey="schedule"
                    >
                      <FormattedMessage id="OBJECT_EXPLORER.SCHEDULES" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="pl-2 border-bottom mr-0"
                      eventKey="dns_record"
                    >
                      <FormattedMessage id="OBJECT_EXPLORER.DNS_RECORDS" />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
            <Col md={9} sm={12} className="px-0">
              <Tab.Content>
                <Tab.Pane eventKey="network" mountOnEnter unmountOnExit>
                  <NetworkObjectList type="network" />
                </Tab.Pane>
                <Tab.Pane eventKey="network_range" mountOnEnter unmountOnExit>
                  <NetworkObjectList type="network_range" />
                </Tab.Pane>
                <Tab.Pane eventKey="fqdn" mountOnEnter unmountOnExit>
                  <NetworkObjectList type="fqdn" />
                </Tab.Pane>
                <Tab.Pane eventKey="geolocation" mountOnEnter unmountOnExit>
                  <NetworkObjectList type="geolocation" />
                </Tab.Pane>
                <Tab.Pane eventKey="mac" mountOnEnter unmountOnExit>
                  <NetworkObjectList type="mac" />
                </Tab.Pane>
                <Tab.Pane eventKey="user" mountOnEnter unmountOnExit>
                  <NetworkObjectList type="user" />
                </Tab.Pane>
                <Tab.Pane eventKey="casb_tenant" mountOnEnter unmountOnExit>
                  <NetworkObjectList type="casb_tenant" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="service-objects-tcp"
                  mountOnEnter
                  unmountOnExit
                >
                  <ServiceObjects type="tcp_service" key="tcp_service" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="service-objects-udp"
                  mountOnEnter
                  unmountOnExit
                >
                  <ServiceObjects type="udp_service" key="udp_service" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="service-objects-icmp"
                  mountOnEnter
                  unmountOnExit
                >
                  <ServiceObjects type="icmp_service" key="icmp_service" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="service-objects-others"
                  mountOnEnter
                  unmountOnExit
                >
                  <ServiceObjects type="other_service" key="other_service" />
                </Tab.Pane>
                <Tab.Pane eventKey="network-group" mountOnEnter unmountOnExit>
                  <NetworkObjects type="network" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="network_range-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="network_range" />
                </Tab.Pane>
                <Tab.Pane eventKey="fqdn-group" mountOnEnter unmountOnExit>
                  <NetworkObjects type="fqdn" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="geolocation-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="geolocation" />
                </Tab.Pane>
                <Tab.Pane eventKey="mac-group" mountOnEnter unmountOnExit>
                  <NetworkObjects type="mac" />
                </Tab.Pane>
                <Tab.Pane eventKey="user-group" mountOnEnter unmountOnExit>
                  <NetworkObjects type="user" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="tcp_service-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="tcp_service" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="udp_service-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="udp_service" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="icmp_service-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="icmp_service" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="other_service-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="other_service" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="dlp_file_types-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="dlp_file_type" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="dlp_file_names-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="dlp_file_name" />
                </Tab.Pane>
                <Tab.Pane eventKey="schedule" mountOnEnter unmountOnExit>
                  <ScheduleObjectList type="schedule" />
                </Tab.Pane>
                <Tab.Pane eventKey="dlp_file_types" mountOnEnter unmountOnExit>
                  <DlpObjectList type="dlp_file_type" />
                </Tab.Pane>
                <Tab.Pane eventKey="dlp_file_names" mountOnEnter unmountOnExit>
                  <DlpObjectList type="dlp_file_name" />
                </Tab.Pane>
                <Tab.Pane eventKey="dlp_contents" mountOnEnter unmountOnExit>
                  <DlpObjectList type="dlp_content" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="dlp_content-group"
                  mountOnEnter
                  unmountOnExit
                >
                  <NetworkObjects type="dlp_content" />
                </Tab.Pane>
                <Tab.Pane eventKey="dns_record" mountOnEnter unmountOnExit>
                  <DnsRecordObjectList type="dns_record" />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default ObjectExplorerModal;