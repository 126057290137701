import React from 'react';

import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import WebhookIcon from '@mui/icons-material/Webhook';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import AppsIcon from '@mui/icons-material/Apps';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import VpnLockIcon from '@mui/icons-material/VpnLock';

const schema = [
  {
    name: 'firewall',
    displayName: 'ADMINISTRATION.FIREWALL',
    icon: <GppGoodOutlinedIcon />,
  },
  {
    name: 'network',
    displayName: 'ADMINISTRATION.NETWORK',
    menu: [
      {
        name: 'ztna',
        displayName: 'ADMINISTRATION.ZTNA',
        icon: <BlockOutlinedIcon />,
      },
      {
        name: 'remote-access',
        displayName: 'ADMINISTRATION.REMOTE-ACCESS',
        icon: <VpnLockIcon />,
        menu: [
          {
            name: 'ssl-vpn',
            displayName: 'ADMINISTRATION.SSL-VPN',
          },
          {
            name: 'ipsec-vpn',
            displayName: 'ADMINISTRATION.IPSEC-VPN',
          },
        ],
      },
      {
        name: 'vpn-tunnel',
        displayName: 'ADMINISTRATION.VPN-TUNNEL',
        icon: <VpnKeyOutlinedIcon />,
      },
      {
        name: 'ssl',
        displayName: 'ADMINISTRATION.SSL',
        icon: <PublicIcon />,
      },
    ]
  },
  {
    name: 'content_control',
    displayName: 'ADMINISTRATION.CONTENT_CONTROL',
    isCarrierConf: true,
    menu: [
      {
        name: 'dns-filter',
        displayName: 'ADMINISTRATION.DNS-FILTER',
        icon: <WebhookIcon />,
      },
      {
        name: 'web-filter',
        displayName: 'ADMINISTRATION.WEB-FILTER',
        icon: <WebhookIcon />,
        isCarrierConf: true,
      },
      {
        name: 'dlp',
        displayName: 'ADMINISTRATION.DLP',
        icon: <LocalPoliceIcon />,
      },
      {
        name: 'applications',
        displayName: 'ADMINISTRATION.APPLICATIONS',
        icon: <AppsIcon />,
        isCarrierConf: true,
      },
      {
        name: 'casb',
        displayName: 'ADMINISTRATION.CASB',
        icon: <AssignmentIcon />,
      },
    ]
  },
  {
    name: 'threat_prevention',
    displayName: 'ADMINISTRATION.THREAT_PREVENTION',
    isCarrierConf: true,
    menu: [
      {
        name: 'antivirus',
        displayName: 'ADMINISTRATION.ANTIVIRUS',
        icon: <HealthAndSafetyIcon />,
        isCarrierConf: true,
      },
      {
        name: 'ips-protection',
        displayName: 'ADMINISTRATION.IPS_GROUP',
        icon: <LockIcon />,
        isCarrierConf: true,
        menu: [
          {
            name: 'ips',
            displayName: 'ADMINISTRATION.IPS',
            isCarrierConf: true,
          },
          {
            name: 'ot',
            displayName: 'ADMINISTRATION.OT',
            isCarrierConf: true,
          },
        ],
      },
      {
        name: 'antispam',
        displayName: 'ADMINISTRATION.ANTISPAM',
        icon: <WebhookIcon />,
      },
    ]
  }
];

export default schema;