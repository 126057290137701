import React, { useState } from "react";
import { Form } from "react-bootstrap";

const BeSafeTimeRange = (props) => {
  const parseTime = value => {
    return value
      .split(":")
      .map((p) => parseInt(p));
  };

  const [hoursVal, minutesVal] = parseTime(props.value);

  const [ hours, setHours ] = useState(hoursVal);
  const [ minutes, setMinutes ] = useState(minutesVal);

  const onChangeHours = (e) => {
    const newHours = parseInt(e.target.value);
    let newMinutes = minutes;

    if (newHours === 24 && newMinutes !== 0) {
      newMinutes = 0;
      setMinutes(0);
    }
    setHours(newHours);
    props.handleChange(formatTime(newHours, newMinutes))
  };

  const onChangeMinutes = (e) => {
    let newMinutes = parseInt(e.target.value);

    if (hours === 24 && newMinutes !== 0) {
      newMinutes = 0;
    }
    setMinutes(newMinutes);
    props.handleChange(formatTime(hours, newMinutes))
  };

  const formatTime = (hours, minutes) => {
    const hoursFormat = hours < 10 ? `0${hours}` : hours;
    const minutesFormat = minutes < 10 ? `0${minutes}` : minutes;

    return `${hoursFormat}:${minutesFormat}`;
  };

  return (
    <div className="d-flex">
      <Form.Control
        as="select"
        name={`${props.name}Hour`}
        className="border-secondary"
        value={hours}
        onChange={onChangeHours}
        custom
      >
        {[...Array(25).keys()].map(h => {
          return (
            <option key={h} value={h}>
              { h < 10 ? `0${h}` : h }
            </option>
          )
        })}
      </Form.Control>
      <span className="p-1">:</span>
      <Form.Control
        as="select"
        name={`${props.name}Minute`}
        className="border-secondary"
        value={minutes}
        onChange={onChangeMinutes}
        custom
      >
        {[0, 15, 30, 45].map(m => {
          return (
            <option key={m} value={m}>
              { m < 10 ? `0${m}` : m }
            </option>
          )
        })}
      </Form.Control>
    </div>
  );
};

export default BeSafeTimeRange;
