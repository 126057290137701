import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";

import { createNetwork, updateNetwork } from "app/crud/networks.crud";
import { successToast, errorToast } from "utils/toastUtils";

const AddServiceModal = (props) => {
  const intl = useIntl();

  return (
    <Modal show={props.showModal} onHide={props.onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>{props.editMode ?
          <FormattedMessage id="SERVICES.EDIT" /> :
          <FormattedMessage id="SERVICES.ADD" />
        }</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          name: props.editMode ? props.selectedService?.name : "",
          ports: props.editMode ? props.selectedService?.data.portrange : "",
          icmp_type: props.editMode ? props.selectedService?.data.icmp_type : "",
          icmp_code: props.editMode ? props.selectedService?.data.icmp_code : "",
          protocol_number: props.editMode ? props.selectedService?.data.protocol_number : "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "GENERAL.REQUIRED_FIELD",
            });
          }

          if (["tcp_service", "udp_service"].includes(props.protocol) && !values.ports) {
            errors.ports = intl.formatMessage({
              id: "GENERAL.REQUIRED_FIELD",
            });
          }

          if (["icmp_service"].includes(props.protocol)) {
            if (!values.icmp_type) {
              errors.icmp_type = intl.formatMessage({
                id: "GENERAL.REQUIRED_FIELD",
              });
            } else if (!values.icmp_code) {
              errors.icmp_code = intl.formatMessage({
                id: "GENERAL.REQUIRED_FIELD",
              });
            }
          }

          if (["other_service"].includes(props.protocol) && !values.protocol_number) {
            errors.protocol_number = intl.formatMessage({
              id: "GENERAL.REQUIRED_FIELD",
            });
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          let params = {
            name: values.name,
            type: props.protocol,
          };

          if (props.protocol === "tcp_service") {
            params["protocol_type"] = "TCP";
            params["portrange"] = values.ports;
          } else if (props.protocol === "udp_service") {
            params["protocol_type"] = "UDP";
            params["portrange"] = values.ports;
          } else if (props.protocol === "icmp_service") {
            params["protocol_type"] = "ICMP";
            params["icmp_type"] = values.icmp_type;
            params["icmp_code"] = values.icmp_code;
          } else if (props.protocol === "other_service") {
            params["protocol_type"] = "IP";
            params["protocol_number"] = values.protocol_number;
          }

          if (props.editMode) {
            updateNetwork({ ...params, id: props.selectedService.id })
              .then((response) => {
                props.onUpdate(params);
                successToast({
                  body: "SERVICES.EDIT_SUCCESS",
                  intl: intl,
                });
              })
              .catch((err) => {
                errorToast({
                  body: err.response.data?.error
                    ? err.response.data.error : "SERVICES.EDIT_ERROR",
                  intl: intl
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            createNetwork(params)
              .then((response) => {
                props.onCreate(params.name);
                successToast({
                  body: "SERVICES.ADD_SUCCESS",
                  intl: intl,
                });
              })
              .catch((err) => {
                errorToast({
                  body: err.response.data?.error
                    ? err.response.data.error : "SERVICES.ADD_ERROR",
                  intl: intl
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="name">
                <Form.Label>
                  <FormattedMessage id="GENERAL.NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  isInvalid={touched.name && errors.name}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "GENERAL.NAME_PLACEHOLDER",
                  })}
                  spellCheck="false"
                  value={values.name}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              {["tcp_service", "udp_service"].includes(props.protocol) && (
                <>
                  <Form.Group controlId="ports">
                    <Form.Label>
                      <FormattedMessage id="SERVICES.PORTS" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="ports"
                      onChange={handleChange}
                      isInvalid={touched.ports && errors.ports}
                      className="border-secondary"
                      placeholder={intl.formatMessage({
                        id: "SERVICES.PORTS_PLACEHOLDER",
                      })}
                      spellCheck="false"
                      value={values.ports}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ports}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              {["icmp_service"].includes(props.protocol) && (
                <>
                  <Form.Group controlId="icmp_type">
                    <Form.Label>
                      <FormattedMessage id="SERVICES.ICMP_TYPE" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="icmp_type"
                      onChange={handleChange}
                      isInvalid={touched.icmp_type && errors.icmp_type}
                      className="border-secondary"
                      placeholder={intl.formatMessage({
                        id: "SERVICES.ICMP_TYPE_PLACEHOLDER",
                      })}
                      spellCheck="false"
                      value={values.icmp_type}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.icmp_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="icmp_code">
                    <Form.Label>
                      <FormattedMessage id="SERVICES.ICMP_CODE" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="icmp_code"
                      onChange={handleChange}
                      isInvalid={touched.icmp_code && errors.icmp_code}
                      className="border-secondary"
                      placeholder={intl.formatMessage({
                        id: "SERVICES.ICMP_CODE_PLACEHOLDER",
                      })}
                      spellCheck="false"
                      value={values.icmp_code}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.icmp_code}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              {["other_service"].includes(props.protocol) && (
                <>
                  <Form.Group controlId="protocol_number">
                    <Form.Label>
                      <FormattedMessage id="SERVICES.PROTOCOL_NUMBER" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="protocol_number"
                      onChange={handleChange}
                      isInvalid={
                        touched.protocol_number && errors.protocol_number
                      }
                      className="border-secondary"
                      placeholder={intl.formatMessage({
                        id: "SERVICES.PROTOCOL_NUMBER_PLACEHOLDER",
                      })}
                      spellCheck="false"
                      value={values.protocol_number}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.protocol_number}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="py-3">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                <i className="fas fa-check mr-2" />
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddServiceModal;
