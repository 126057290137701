import React, { useState, useEffect, useCallback } from "react";
import { ListGroup } from "react-bootstrap";
import { useIntl } from "react-intl";

import { deleteNetwork, getNetworks } from "app/crud/networks.crud";
import { successToast, errorToast, warningToast } from "utils/toastUtils";
import { ConfirmationPopup } from "components/ConfirmDialog";
import BeSafeSearchBar from "components/commons/BeSafeSearchBar";
import TableLoader from "components/commons/TableLoader";
import NoDataDisplay from "components/commons/NoDataDisplay";
import BeSafeContainer from "components/commons/BeSafeContainer";
import AddDnsRecordModal from "components/object_explorer/dns_records/AddDnsRecordModal";
import DnsRecordSubList from "components/object_explorer/dns_records/DnsRecordSubList";
import { Add } from "@mui/icons-material";
import BeSafeButton from "components/commons/BeSafeButton";

const DnsRecordObjectList = (props) => {
  const [dnsRecords, setDnsRecords] = useState([]);
  const [selectedDnsRecord, setSelectedDnsRecord] = useState([]);
  const usedList = [];

  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const intl = useIntl();

  const selectDnsRecord = useCallback(
    (dnsRecord) => {
      setPreviewLoading(true);
      setSelectedDnsRecord(dnsRecord);
      setPreviewLoading(false);
    },
    []
  );

  const fetchDnsRecords = useCallback(
    (q) => {
      setLoading(true);
      setPreviewLoading(true);
      getNetworks({ type: props?.type, q })
        .then((response) => {
          setDnsRecords(response.data.objects);
          if (response.data.objects.length) {
            selectDnsRecord(response.data.objects[0]);
          }
        })
        .catch((error) => {
          errorToast({ body: "DNS_RECORD_OBJECTS.LOAD_ERROR", intl: intl });
        })
        .finally(() => {
          setLoading(false);
          setPreviewLoading(false);
        });
    },
    [intl, selectDnsRecord, props?.type]
  );

  useEffect(() => {
    fetchDnsRecords(searchQuery);
  }, [fetchDnsRecords, searchQuery]);

  const showEditDnsRecordModal = () => {
    setEditMode(true);
    setShowModal(true);
  };

  const showNewDnsRecordModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const deleteDnsRecordObject = () => {
    if (usedList.length > 0) {
      warningToast({
        body: "DNS_RECORD_OBJECTS.ALREADY_IN_USE",
        intl: intl,
      });
    } else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "GENERAL.WARNING",
        }),
        description: intl.formatMessage({
          id: "DNS_RECORD_OBJECTS.CONFIRM_DELETE",
        }),
        okLabel: intl.formatMessage({
          id: "GENERAL.OK",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          deleteNetwork(selectedDnsRecord.id)
            .then((response) => {
              let vals = dnsRecords.filter((nw) => nw.id !== selectedDnsRecord.id);
              setDnsRecords(vals);
              if (vals.length) selectDnsRecord(vals[0]);
              successToast({
                body: "DNS_RECORD_OBJECTS.DNS_RECORD_OBJECT_DELETED_SUCCESS",
                intl: intl,
              });
            })
            .catch((err) => {
              if (err.response.data.already_in_used) {
                errorToast({
                  body: "DNS_RECORD_OBJECTS.ALREADY_IN_USE",
                  intl: intl,
                });
              } else {
                errorToast({
                  body: err.response.data?.error,
                  intl: intl,
                });
              }
            });
        },
      });
    }
  };

  const onCreateDnsRecord = () => {
    fetchDnsRecords(searchQuery);
    setShowModal(false);
  };

  return (
    <div className="d-flex obj-cus-h-35 py-2">
      <div className="d-flex card flex-column w-40 h-100 mr-3">
        <div className="d-flex justify-content-between pl-3">
          <BeSafeSearchBar
            onSearch={setSearchQuery}
          />
          <BeSafeButton
            variant="transparent"
            className="py-0 ml-auto px-1"
            onClick={showNewDnsRecordModal}
            icon={<Add />}
            tooltip="GENERAL.ADD"
          />
        </div>
        <div className="flex-grow-1 overflow-auto">
          <TableLoader visible={loading} rows={10} />
          <NoDataDisplay visible={!loading && dnsRecords.length <= 0} />
          <BeSafeContainer visible={!loading && dnsRecords.length > 0}>
            <div className="d-flex flex-column h-100">
              <ListGroup className="flex-grow-1 overflow-auto">
                {dnsRecords.map((dnsRecord) => (
                  <ListGroup.Item
                    className="rounded-0"
                    active={dnsRecord.id === selectedDnsRecord.id}
                    key={dnsRecord.id}
                    action
                    onClick={() => selectDnsRecord(dnsRecord)}
                  >
                    {dnsRecord.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </BeSafeContainer>
        </div>
      </div>
      <>
        <DnsRecordSubList
          previewLoading={previewLoading}
          dnsRecords={dnsRecords}
          selectedDnsRecord={selectedDnsRecord}
          deleteDnsRecordObject={deleteDnsRecordObject}
          showEditDnsRecordModal={showEditDnsRecordModal}
        />
        <AddDnsRecordModal
          editMode={editMode}
          showModal={showModal}
          onHide={() => setShowModal(false)}
          onCreate={() => onCreateDnsRecord()}
          onUpdate={() => onCreateDnsRecord()}
          selectedDnsRecord={selectedDnsRecord}
        />
      </>
    </div>
  );
};

export default DnsRecordObjectList;
