import axios from "axios";

const BASE_URL = "/tasks";

export function scheduleReport(params) {
  return axios.post(`${BASE_URL}/schedule_report`, params);
}

export function getScheduledReports(params) {
  return axios.get(`${BASE_URL}/get_scheduled_reports`, { params });
}

export function deleteScheduledReport(params) {
  return axios.delete(`${BASE_URL}/delete_scheduled_report`, { params });
}

export function installConfiguration(params) {
  return axios.post(`${BASE_URL}/install_configuration`, params);
}

export function installDeviceConfiguration(params) {
  return axios.post(`${BASE_URL}/install_device_configuration`, params);
}

export function restoreDeviceConfiguration(params) {
  return axios.post(`${BASE_URL}/restore_device_configuration`, params);
}

export function findAllTasks(params) {
  return axios.get(`${BASE_URL}`, { params });
}

export function getTask(task_id) {
  return axios.get(`${BASE_URL}/${task_id}`, {})
}