import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import { Formik } from "formik";
import { createNetwork, updateNetwork } from "app/crud/networks.crud";

import { successToast, errorToast } from "utils/toastUtils";
import BeSafeButton from "components/commons/BeSafeButton";

const AddCasbTenantModal = ({
  editMode,
  selectedNetwork,
  showModal,
  onHide,
  onCreate,
  onUpdate,
}) => {
  const intl = useIntl();

  const initialNetworkValues = {
    name: editMode ? selectedNetwork?.name : "",
    target: editMode ? selectedNetwork?.target : "",
  };

  return (
    <Modal show={showModal} onHide={onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {editMode ? (
            <FormattedMessage id="CASB_TENANT_OBJECTS.EDIT" />
          ) : (
            <FormattedMessage id="CASB_TENANT_OBJECTS.ADD" />
          )}
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          ...initialNetworkValues,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          let params = {
            name: values.name,
            target: values.target,
            type: "casb_tenant",
          };

          if (editMode) {
            updateNetwork({ ...params, id: selectedNetwork.id })
              .then((response) => {
                onUpdate(params);
                successToast({
                  body: "CASB_TENANT_OBJECTS.EDIT_SUCCESS",
                  intl: intl,
                });
              })
              .catch((err) => {
                errorToast({ body: err.response.data?.error, intl: intl });
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            createNetwork(params)
              .then((response) => {
                onCreate();
                successToast({
                  body: "CASB_TENANT_OBJECTS.ADD_SUCCESS",
                  intl: intl,
                });
              })
              .catch((err) => {
                errorToast({ body: err.response.data?.error, intl: intl });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name || !values.name.trim()) {
            errors.name = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD" });
          }

          if (values.name.length > 64) {
            errors.name = intl.formatMessage({
              id: "GENERAL.MAX_NAME_SIZE",
            });
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="name">
                <Form.Label>
                  <FormattedMessage id="GENERAL.NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  isInvalid={errors.name}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "GENERAL.NAME_PLACEHOLDER",
                  })}
                  value={values.name}
                  required
                />
                {errors.name && touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="target">
                <Form.Label>
                  <FormattedMessage id="CASB_TENANT_OBJECTS.TARGET" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="target"
                  onChange={handleChange}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "NETWORK_OBJECTS.CASB_TENANT_PLACEHOLDER",
                  })}
                  value={values.target}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <BeSafeButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                text={<FormattedMessage id="GENERAL.SAVE" />}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCasbTenantModal;
