import { OverlayTrigger, Tooltip } from "react-bootstrap";

const BeSafeTooltip = ({tooltip, placement, children}) => {
  return tooltip && tooltip !== "" ? (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id="tooltip-1">{tooltip}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  );
};

BeSafeTooltip.defaultProps = {
  placement: "bottom"
}

export default BeSafeTooltip;
