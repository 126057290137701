import React from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";
import _ from "lodash";

import { createNetworksGroup, updateNetworksGroup } from "app/crud/networks.crud";
import { successToast, errorToast } from "utils/toastUtils";
import NetworksSelect from "components/configuration/firewall/modals/NetworksSelect";
import BeSafeButton from "components/commons/BeSafeButton";
import BeSafeButtonGroup from "components/commons/BeSafeButtonGroup";
import BeSafeControlFeedback from "components/commons/BeSafeControlFeedback";

const NetworkFormModal = (props) => {
  const intl = useIntl();

  return (
    <Modal show={props.showModal} onHide={props.onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id={
              props.editMode
                ? "OBJECT_EXPLORER." + props.objectType.toUpperCase() + "_GROUP_EDIT"
                : "OBJECT_EXPLORER." + props.objectType.toUpperCase() + "_GROUP_ADD"
            }
          />
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          name: props.editMode ? props.networkGroup?.name : "",
          objects: props.editMode ? props.networkList : [],
          ipProtocol: props.objectType.match(/^(network|network_range|user)$/) ?
              (!props.editMode || props.networkGroup.ip_protocol === null
                ? "ipv4" : props.networkGroup.ip_protocol) : null,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name || !values.name.trim()) {
            errors.name = intl.formatMessage({
              id: "GENERAL.REQUIRED_FIELD",
            });
          }

          if (values.name.length > 64) {
            errors.name = intl.formatMessage({
              id: "GENERAL.MAX_NAME_SIZE",
            });
          }

          if (_.isEmpty(values.objects)) {
            errors.objects = intl.formatMessage({
              id: "GENERAL.REQUIRED_FIELD",
            });
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          let params = {
            id: props.networkGroup?.id,
            name: values.name,
            ip_protocol: values.ipProtocol,
            objects_ids: values.objects.map((v) => v.id),
            type: props.objectType,
          };

          if (props.editMode) {
            updateNetworksGroup(params)
              .then((response) => {
                props.parentCallback(params);
                successToast({ body: "NETWORK_OBJECTS.GROUP_EDIT_SUCCESS", intl: intl });
              })
              .catch((err) => {
                errorToast({
                  body: err.response.data?.error
                    ? err.response.data.error : "NETWORK_OBJECTS.GROUP_EDIT_ERROR",
                  intl: intl
                });
              })
              .finally(setSubmitting(false));
          } else {
            createNetworksGroup(params)
              .then((response) => {
                props.parentCallback();
                successToast({ body: "NETWORK_OBJECTS.GROUP_ADD_SUCCESS", intl: intl });
              })
              .catch((err) => {
                errorToast({
                  body: err.response.data?.error
                    ? err.response.data.error : "NETWORK_OBJECTS.GROUP_ADD_ERROR",
                  intl: intl
                });
              })
              .finally(setSubmitting(false));
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group controlId="name">
                    <Form.Label>
                      <FormattedMessage id="GENERAL.NAME" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleChange}
                      isInvalid={errors.name && touched.name}
                      className="border-secondary"
                      placeholder={intl.formatMessage({
                        id: "GENERAL.NAME_PLACEHOLDER",
                      })}
                      spellCheck="false"
                      value={values.name}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              { values.ipProtocol &&
                <Row>
                  <Col>
                    <Form.Group controlId="ipProtocol">
                      <Form.Label>
                        <FormattedMessage id="FIREWALL.PROTOCOL" />
                      </Form.Label>
                      <BeSafeButtonGroup
                        name="ipProtocol"
                        groupPrefix={"NETWORK_OBJECTS"}
                        groups={["ipv4", "ipv6"]}
                        checked={values.ipProtocol}
                        onChange={(ipProtocol) => {
                          setFieldValue("ipProtocol", ipProtocol);
                          setFieldValue("objects", []);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              }
              <Row>
                <Col>
                  <Form.Group controlId="objects">
                    <Form.Label>
                      <FormattedMessage id="OBJECT_EXPLORER.OBJECT_LIST" />
                    </Form.Label>
                    <NetworksSelect
                      objectType={props.objectType}
                      initialTags={values.objects}
                      handleChange={(val) =>
                        setFieldValue(
                          "objects",
                          val.map((v) => v)
                        )
                      }
                      selectType={props.selectType}
                      ipProtocol={values.ipProtocol}
                    />
                    <BeSafeControlFeedback>
                      {errors.objects}
                    </BeSafeControlFeedback>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <BeSafeButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                text={<FormattedMessage id="GENERAL.SAVE" />}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default NetworkFormModal;
