import axios from 'axios';

const BASE_SURICATA_RULES = '/suricata_rules';
const BASE_OT_RULES = '/ot_rules';
const CONFIGURATION_CHANGES = '/configuration_changes';

// SURICATA RULES CONFIGURATION //

export function findAllSuricataRules(params) {
  return axios.get(`${BASE_SURICATA_RULES}`, { params: params });
}

export function getCountNotificacionChanges() {
  return axios.get(`${CONFIGURATION_CHANGES}/count`);
}

export function getConfigurationChanges(id) {
  return axios.get(`${CONFIGURATION_CHANGES}/${id}`);
}

export function update_multiple_rules_action(params) {
  return axios.patch(
    `${BASE_SURICATA_RULES}/update_multiple_rules_action`,
    params
  );
}

export function updateRuleAction(suricataId, params) {
  return axios.patch(`${BASE_SURICATA_RULES}/${suricataId}`, params);
}

export function discardAllChangesConfiguration(id) {
  return axios.delete(`${CONFIGURATION_CHANGES}/${id}/`);
}

// OT RULES CONFIGURATION //
export function findAllOtRules(params) {
  return axios.get(`${BASE_OT_RULES}`, { params: params });
}

export function update_multiple_ot_rules_action(params) {
  return axios.patch(`${BASE_OT_RULES}/update_multiple_rules_action`, params);
}

// GENERAL CONFIGURATIONS //

export function findMainConfigurations() {
  return axios.get('/main_configurations');
}
