import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionTypes = {
  SetPolicyChanges: "[Load Policy change count] Action",
  SetPublishingStatus: "[Change Publishing Status] Action",
  SetPublishingAction: "[Change Publishing Action] Action",
  SetTaskID: "[Change Task ID] Action",
  SetInstallationProgress: "[Change Installation Progress] Action",
};

const initialState = {
  changeCount: 0,
  isPublishing: false,
  publishingAction: "",
  taskID: "",
  installationProgress: 0,
};

export const reducer = persistReducer(
  { storage, key: "policy" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetPolicyChanges:
        return { ...state, changeCount: action.payload.changeCount };

      case actionTypes.SetPublishingStatus:
        return { ...state, isPublishing: action.payload.isPublishing };

      case actionTypes.SetPublishingAction:
        return { ...state, publishingAction: action.payload.publishingAction };

      case actionTypes.SetTaskID:
        return { ...state, taskID: action.payload.taskID };

      case actionTypes.SetInstallationProgress:
        return { ...state, installationProgress: action.payload.val };

      default:
        return state;
    }
  }
);

export const SetPolicyChanges = (changeCount) => ({
  type: actionTypes.SetPolicyChanges,
  payload: { changeCount },
});

export const SetPublishingStatus = (isPublishing) => ({
  type: actionTypes.SetPublishingStatus,
  payload: { isPublishing },
});

export const SetPublishingAction = (publishingAction) => ({
  type: actionTypes.SetPublishingAction,
  payload: { publishingAction },
});

export const SetTaskID = (taskID) => ({
  type: actionTypes.SetTaskID,
  payload: { taskID },
});

export const SetInstallationProgress = (val) => ({
  type: actionTypes.SetInstallationProgress,
  payload: { val },
});
