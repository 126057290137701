import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";

import { createNetwork, updateNetwork } from "app/crud/networks.crud";
import { isValidDomainName, isValidIp } from "utils/utils";
import { errorToast } from 'utils/toastUtils';
import BeSafeButton from 'components/commons/BeSafeButton';

const AddDnsRecordModal = ({
  editMode,
  selectedDnsRecord,
  showModal,
  onHide,
  onCreate,
  onUpdate,
}) => {
  const intl = useIntl();

  const initialValues = {
    domain_name: editMode ? selectedDnsRecord?.name : "",
    description: editMode ? selectedDnsRecord?.description : "",
    ip: editMode ? selectedDnsRecord?.target : "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.domain_name || !values.domain_name.trim()) {
      errors.domain_name = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD", });
    } else if (!isValidDomainName(values.domain_name)) {
      errors.domain_name = intl.formatMessage({ id: "GENERAL.INVALID_DOMAIN_NAME", });
    }

    if (!values.ip || !values.ip.trim()) {
      errors.ip = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD", });
    } else if (!isValidIp(values.ip)) {
      errors.ip = intl.formatMessage({ id: "GENERAL.INVALID_IP", });
    }

    return errors;
  };

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);

    let params = {
      name: values.domain_name,
      description: values.description,
      target: values.ip,
      type: "dns_record",
    };

    if (editMode) {
      updateNetwork({ ...params, id: selectedDnsRecord.id })
        .then((response) => {
          onUpdate(params);
        })
        .catch(err => {
          errorToast({ body: err.response.data?.error, intl: intl });
        })
        .finally(setSubmitting(false));
    } else {
      createNetwork(params)
        .then((response) => {
          onCreate();
        })
        .catch(err => {
          errorToast({ body: err.response.data?.error, intl: intl });
        })
        .finally(setSubmitting(false));
    }
  };

  return (
    <Modal show={showModal} onHide={onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>{editMode ?
          <FormattedMessage id="DNS_RECORD_OBJECTS.EDIT" /> :
          <FormattedMessage id="DNS_RECORD_OBJECTS.ADD" />
        }</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="domain_name">
                <Form.Label>
                  <FormattedMessage id="GENERAL.DOMAIN_NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="domain_name"
                  onChange={handleChange}
                  isInvalid={touched.domain_name && errors.domain_name}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "GENERAL.DOMAIN_NAME_PLACEHOLDER",
                  })}
                  spellCheck="false"
                  value={values.domain_name}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.domain_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>
                  <FormattedMessage id="GENERAL.DESCRIPTION" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  onChange={handleChange}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "GENERAL.DESCRIPTION_PLACEHOLDER",
                  })}
                  spellCheck="false"
                  value={values.description}
                />
              </Form.Group>
              <Form.Group controlId="ip">
                <Form.Label>
                  <FormattedMessage id="OBJECT_EXPLORER.IPV4" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="ip"
                  onChange={handleChange}
                  isInvalid={touched.ip && errors.ip}
                  className="border-secondary"
                  placeholder="2.2.2.2"
                  spellCheck="false"
                  value={values.ip}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.ip}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <BeSafeButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                text={<FormattedMessage id="GENERAL.SAVE" />}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddDnsRecordModal;
